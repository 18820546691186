import React from "react";
import { RouteObject } from "react-router";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { analyzerRoute } from "../../Analyzer/routes/analyzer";
import { demandRoute } from "../../Demand/routes/demand";
import BasicLayout from "../components/layouts/BasicLayout";
import GeneralDemandLayout from "../components/layouts/DemandGeneralLayout";
import DemandSolutionLayout from "../components/layouts/DemandSolutionLayout";
import GeneralLayout from "../components/layouts/GeneralLayout";
import SolutionLayout from "../components/layouts/SolutionLayout";
// integral pages
import Error from "../pages/ErrorPage/ErrorPage";
import Login from "../pages/Login/Login";
// integral components
import RequireAuth from "../pages/Login/RequireAuth";
import NotFound from "../pages/NotFound/NotFound";
import Solutions from "../pages/Solutions/Solutions";
import { businessHoursRoute } from "./businessHours";
import { helpRoute } from "./help";
// feature routes
import { importsRoute } from "./imports";
import { influencingFactorsRoute } from "./influencingFactors";
import { modelsRoute } from "./models";
import { outliersRoute } from "./outliers";
import { resultsRoute } from "./results";
import { reviewRoute } from "./review";
import { runConfigsRoute } from "./runConfigs";
import { solutionSettingsRoute } from "./solutionSettings";
import { SOLUTIONS_PATH } from "./solutions";
import { systemRoute } from "./system";
import { tasksRoute } from "./tasks";
import { userSettingsRoute } from "./userSettings";

const Solution = lazyWithRetry(() => import("../pages/Solution/Solution"));
const DemandOverview = lazyWithRetry(
  () => import("../../Demand/pages/DemandOverview/DemandOverview")
);
const Analyzer = lazyWithRetry(
  () => import("../../Analyzer/pages/Analyzer/Analyzer")
);

export const routes: RouteObject[] = [
  {
    path: "demand",
    errorElement: (
      <BasicLayout>
        <Error />
      </BasicLayout>
    ),
    children: [
      {
        index: true,
        element: (
          <BasicLayout>
            <Login basePath="/demand" />
          </BasicLayout>
        ),
      },
      {
        path: SOLUTIONS_PATH,
        element: (
          <RequireAuth>
            <GeneralDemandLayout />
          </RequireAuth>
        ),
        children: [{ index: true, element: <Solutions /> }, userSettingsRoute],
      },
      {
        path: `${SOLUTIONS_PATH}/:solutionId`,
        element: (
          <RequireAuth>
            <DemandSolutionLayout />
          </RequireAuth>
        ),
        children: [
          { index: true, element: <DemandOverview /> },
          demandRoute,
          userSettingsRoute,
        ],
      },
    ],
  },
  {
    path: "analyzer",
    errorElement: (
      <BasicLayout>
        <Error />
      </BasicLayout>
    ),
    children: [
      {
        index: true,
        element: (
          <BasicLayout>
            <Login basePath="/analyzer" />
          </BasicLayout>
        ),
      },
      {
        path: SOLUTIONS_PATH,
        element: (
          <RequireAuth>
            <GeneralDemandLayout />
          </RequireAuth>
        ),
        children: [{ index: true, element: <Solutions /> }, userSettingsRoute],
      },
      {
        path: `${SOLUTIONS_PATH}/:solutionId`,
        element: (
          <RequireAuth>
            <Analyzer />
          </RequireAuth>
        ),
        children: [
          { index: true, element: <DemandOverview /> },
          analyzerRoute,
          userSettingsRoute,
        ],
      },
    ],
  },
  {
    path: "/",
    errorElement: (
      <BasicLayout>
        <Error />
      </BasicLayout>
    ),
    children: [
      {
        index: true,
        element: (
          <BasicLayout>
            <Login />
          </BasicLayout>
        ),
      },
      {
        path: SOLUTIONS_PATH,
        element: (
          <RequireAuth>
            <GeneralLayout />
          </RequireAuth>
        ),
        children: [
          { index: true, element: <Solutions /> },
          userSettingsRoute,
          helpRoute,
          tasksRoute,
          systemRoute,
        ],
      },
      {
        path: `${SOLUTIONS_PATH}/:solutionId`,
        element: (
          <RequireAuth>
            <SolutionLayout />
          </RequireAuth>
        ),
        children: [
          { index: true, element: <Solution /> },
          solutionSettingsRoute,
          importsRoute,
          reviewRoute,
          outliersRoute,
          influencingFactorsRoute,
          businessHoursRoute,
          modelsRoute,
          runConfigsRoute,
          resultsRoute,
          userSettingsRoute,
          helpRoute,
          tasksRoute,
        ],
      },
    ],
  },
  {
    path: "*",
    element: (
      <RequireAuth>
        <BasicLayout>
          <NotFound />
        </BasicLayout>
      </RequireAuth>
    ),
  },
];
