import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useQuery } from "@tanstack/react-query";

import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { ArchiveBoxIcon, PlusIcon } from "@heroicons/react/24/outline";

import { EndpointOptions } from "../../../../api";
import Card from "../../../../components/Card";
import Button from "../../../../components/buttons/Button";
import { DEFAULT_PAGE_SIZE } from "../../../../services/constants";
import {
  solutionUsedIdentifiersQuery,
  solutionsQuery,
  useCreateSolution,
} from "../../api/solutions";
import DataTable from "../../components/DataTable";
import Form from "../../components/Form";
import Hero from "../../components/Hero";
import HeroActionButton from "../../components/HeroActionButton";
import HeroSubtitle from "../../components/HeroSubtitle";
import Slideover from "../../components/Slideover";
import TextField from "../../components/form/TextField";
import TimezonePicker from "../../components/form/TimezonePicker";
import useEndpoint from "../../hooks/useEndpoint";
import useSearchBoolean from "../../hooks/useSearchBoolean";
import {
  SolutionDraft,
  SolutionPhaseZod,
  solutionPhases,
} from "../../models/solution";
import { tenum } from "../../services/translationMappings";
import Error from "../ErrorPage/ErrorPage";
import PhaseIcon from "../Solution/components/PhaseIcon";
import { generateSolutionNameError } from "../SolutionSettings/SolutionSettings";

const DEFAULT_SOLUTION: SolutionDraft = {
  name: "",
  dataInterval: "Invalid",
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  description: "",
  created: new Date(),
  createdBy: null,
  phase: "New",
};

const DEFAULT_ENDPOINT_OPTIONS: EndpointOptions = {
  sort: [["name", "asc"]],
  pageSize: DEFAULT_PAGE_SIZE,
};

export default function Solutions(): JSX.Element {
  const [showArchived, setShowArchived] = useSearchBoolean("showArchived");
  const handleArchivedToggle = () => {
    setShowArchived(!showArchived);
  };

  const endpoint = useEndpoint(
    (opts) =>
      solutionsQuery({
        ...opts,
        filter: (opts.filter ?? [])
          .filter((f) => !showArchived || f[0] !== "phase")
          .concat([
            [
              "phase",
              showArchived ? "=" : "!=",
              SolutionPhaseZod.Enum.Archived,
            ],
          ]),
      }),
    DEFAULT_ENDPOINT_OPTIONS
  );
  const { queryResult: solutionsQ, options } = endpoint;
  const { onSortChange, onFilterChange } = endpoint;
  const { items: solutions = [], count } = solutionsQ.data ?? {};
  const { isLoading, isError, error } = solutionsQ;

  const [newSolution, setNewSolution] =
    useState<SolutionDraft>(DEFAULT_SOLUTION);
  const [formOpen, setFormOpen] = useState(false);

  const createSolution = useCreateSolution();
  const handleSubmit = () => {
    createSolution.mutate(newSolution);
  };

  const { t } = useTranslation();

  const solutionNameRef = React.useRef<HTMLInputElement>(null);

  const { data: solutionUsedIdentifiers = [] } = useQuery(
    solutionUsedIdentifiersQuery()
  );

  if (isError) {
    return <Error error={error} />;
  }

  const nameError = generateSolutionNameError(
    null,
    newSolution.name,
    solutionUsedIdentifiers,
    t
  );

  return (
    <div>
      <Hero
        actions={
          <>
            {!showArchived && (
              <HeroActionButton
                id="toggleArchived"
                onClick={handleArchivedToggle}
                title={t("Show archived projects")}
              >
                <ArchiveBoxIcon />
              </HeroActionButton>
            )}
            <HeroActionButton
              id="newSolutionButton"
              onClick={() => setFormOpen(true)}
              title={t("New project")}
              variant="primary"
            >
              <PlusIcon />
            </HeroActionButton>
          </>
        }
      >
        {showArchived ? t("Archived projects") : t("Projects")}
        {!!showArchived && (
          <HeroSubtitle>
            <button
              className="text-sm underline underline-offset-2 text-blue-500"
              onClick={() => setShowArchived(false)}
            >
              <ChevronLeftIcon className="w-4 h-4" />
              {t("Back to active projects")}
            </button>
          </HeroSubtitle>
        )}
      </Hero>
      <Card>
        <DataTable
          id="solutionsTable"
          columns={[
            {
              key: "phase",
              type: "enum",
              label: t("Stage"),
              sortable: true,
              filterable: true,
              content: "icon",
              options: {
                enumOptions: solutionPhases
                  .filter((key) => showArchived || key !== "Archived")
                  .map((key) => ({
                    key,
                    label: tenum(`SolutionPhase:${key}`, t),
                  })),
              },
            },
            {
              key: "name",
              label: t("Name"),
              type: "string",
              highlighted: true,
              filterable: true,
              sortable: true,
            },
            {
              key: "modelsCount",
              label: t("Models"),
              type: "number",
              filterable: true,
              sortable: true,
            },
            {
              key: "scenariosCount",
              label: t("Scenarios"),
              type: "number",
              filterable: true,
              sortable: true,
            },
            {
              key: "influencingFactorsCount",
              label: t("Influencing factors"),
              type: "number",
              filterable: true,
              sortable: true,
            },
            {
              key: "outliersCount",
              label: t("Outliers"),
              type: "number",
              filterable: true,
              sortable: true,
            },
            {
              key: "hasBusinessHours",
              label: t("Business hours"),
              type: "boolean",
              filterable: true,
              sortable: true,
              labelAlign: "text-center",
              align: "center",
            },
            {
              key: "lastImportDate",
              label: t("Last import"),
              type: "datetime",
              filterable: true,
              sortable: true,
            },
            {
              key: "lastResultDate",
              label: t("Last result"),
              type: "datetime",
              filterable: true,
              sortable: true,
            },
          ]}
          rows={solutions.map((s) => ({
            ...s,
            phase: <PhaseIcon phase={s.phase} />,
            createdBy: undefined,
            dataInterval:
              s.dataInterval && s.dataInterval !== "Invalid"
                ? s.dataInterval
                : "",
          }))}
          linkKey="solutionId"
          rowKey="solutionId"
          showSkeleton={isLoading}
          page={options.page}
          pageSize={options.pageSize}
          totalCount={count}
          sort={options.sort}
          filter={options.filter}
          onFilter={onFilterChange}
          onSort={onSortChange}
        />
      </Card>
      <Slideover
        title={t("New project")}
        open={formOpen}
        initialFocus={solutionNameRef}
        onClose={() => setFormOpen(false)}
      >
        <Form onSubmit={handleSubmit}>
          <TextField
            id="solution_name"
            ref={solutionNameRef}
            label={t("Name")}
            value={newSolution.name}
            onChange={(e) =>
              setNewSolution((prev) => ({ ...prev, name: e.target.value }))
            }
            error={nameError}
            supressErrorsBeforeEdit
          />
          <div>
            <TimezonePicker
              id="timezone"
              label={t("Time zone")}
              value={newSolution.timezone}
              onChange={(timezone) =>
                setNewSolution((prev) => ({
                  ...prev,
                  timezone,
                }))
              }
            />
          </div>
          <div className="mt-2 space-x-1 text-right">
            <Button
              id="saveNewSolutionButton"
              disabled={!!nameError}
              type="submit"
            >
              {t("Create project")}
            </Button>
          </div>
        </Form>
      </Slideover>
    </div>
  );
}
