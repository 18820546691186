import React from "react";
import { Link } from "react-router-dom";

import classNames from "classnames";

import TableCell, { TABLE_CELL_PADDING, TableCellProps } from "./TableCell";

interface LinkTableCellProps extends TableCellProps {
  link: string;
}

export default function LinkTableCell(props: LinkTableCellProps): JSX.Element {
  const { link, children, className = "", noPadding, ...rest } = props;

  return (
    <TableCell noPadding {...rest}>
      <Link
        className={classNames(
          "block no-underline",
          noPadding ? "" : TABLE_CELL_PADDING,
          !className.includes("text-") ? "text-inherit" : "",
          className
        )}
        to={link}
      >
        {children}
      </Link>
    </TableCell>
  );
}
