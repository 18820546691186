import React from "react";
import { PlacesType, Tooltip, VariantType } from "react-tooltip";

import classNames from "classnames";

import { HeroIcon } from "../../../models/primitives";
import Portal from "./Portal";

interface IconTooltipProps {
  id: string;
  text: string;
  variant?: VariantType;
  place?: PlacesType;
  iconClassName?: string;
  tooltipClassName?: string;
  icon: HeroIcon;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export default function IconTooltip(props: IconTooltipProps): JSX.Element {
  const { id, text, icon: Icon, variant = "dark", place = "bottom" } = props;
  const { iconClassName = "", tooltipClassName = "", onClick } = props;
  return (
    <>
      <button
        id={`${id}-button`}
        className={classNames("w-4 h-4 flex items-center", iconClassName)}
        data-tooltip-id={id}
        data-tooltip-content={text}
        data-tooltip-place={place}
        onClick={onClick}
      >
        <Icon />
      </button>
      <Portal>
        <Tooltip
          id={id}
          variant={variant}
          className={classNames("!max-w-xs", tooltipClassName)}
          style={{ zIndex: 99999999 }}
        />
      </Portal>
    </>
  );
}
