import React from "react";

export default function SkeletonChart(): JSX.Element {
  return (
    <div className="flex items-baseline mt-4 space-x-6">
      <div className="w-full h-72 bg-gray-200 rounded-t-lg"></div>
      <div className="w-full h-56 bg-gray-200 rounded-t-lg"></div>
      <div className="w-full h-72 bg-gray-200 rounded-t-lg"></div>
      <div className="w-full h-64 bg-gray-200 rounded-t-lg"></div>
      <div className="w-full h-80 bg-gray-200 rounded-t-lg"></div>
      <div className="w-full h-72 bg-gray-200 rounded-t-lg"></div>
      <div className="w-full h-80 bg-gray-200 rounded-t-lg"></div>
    </div>
  );
}
