import { FilterSetting } from "../../../../models/primitives";
import { Operator } from "../filter/OperatorSelect";
import { FilterInstance, FilterInterface } from "../filter/utils";

export const filterValuesToFilterInstance = (
  filterVs: FilterSetting[],
  filterInterface: FilterInterface
): FilterInstance | undefined => {
  if (filterVs.length === 1) {
    const filter = filterVs[0];
    return {
      ...filterInterface,
      operator: textOpToOp(filter[1]),
      parameter: filter[2].toString(),
    };
  }

  if (filterVs.length === 2) {
    const fromFilter = filterVs.find((f) => f[1] === ">=");
    const toFilter = filterVs.find((f) => f[1] === "<=");
    if (!fromFilter || !toFilter) {
      return undefined;
    }

    let [, , from] = fromFilter;
    if (Array.isArray(from)) {
      from = from[0];
    }
    let [, , to] = fromFilter;
    if (Array.isArray(to)) {
      to = to[0];
    }

    // less than 24 hours difference is actually "=" operator
    if (
      Math.abs(
        new Date(to as string | number).valueOf() -
          new Date(from as string | number).valueOf()
      ) <
      24 * 60 * 60 * 1000
    ) {
      return {
        ...filterInterface,
        operator: "=",
        parameter: `${from}`,
      };
    }

    return {
      ...filterInterface,
      operator: "☍",
      parameter: `${fromFilter[2]} - ${toFilter[2]}`,
    };
  }

  return undefined;
};

const textOpToOp = (textOp: FilterSetting[1]): Operator => {
  // from "<" | "<=" | "=" | "!=" | ">=" | ">" | "=*" | "!*" | "^" | "!^" | "$" | "!$"
  // to "<" | "=" | ">" | "≠" | "≤" | "≥" | "☍"
  switch (textOp) {
    case "<":
    case ">":
    case "=":
    case "^":
    case "$":
    case "∅":
    case "*":
      return textOp;
    case "<=":
      return "≤";
    case ">=":
      return "≥";
    case "!=":
      return "≠";
    case "=*":
      return "~";
    default:
      // TODO unsupported params
      return "=";
  }
};

export const filterInstanceToFilterValues = (
  filterI: FilterInstance
): FilterSetting[] => {
  if (filterI.operator === "☍") {
    const [from, to] = filterI.parameter.split(" - ");
    return [
      [filterI.columnKey, ">=", from],
      [filterI.columnKey, "<=", to],
    ];
  }

  if (
    (filterI.type === "date" || filterI.type === "datetime") &&
    filterI.operator === "=" &&
    filterI.parameter !== "" &&
    filterI.parameter !== "undefined"
  ) {
    const from = new Date(filterI.parameter);
    from.setHours(0);
    from.setMinutes(0);
    from.setMilliseconds(0);
    const to = new Date(filterI.parameter);
    to.setHours(23);
    to.setMinutes(59);
    to.setMilliseconds(999);
    return [
      [filterI.columnKey, ">=", from.toISOString()],
      [filterI.columnKey, "<=", to.toISOString()],
    ];
  }

  return [[filterI.columnKey, opToTextOp(filterI.operator), filterI.parameter]];
};

const opToTextOp = (op: Operator): FilterSetting[1] => {
  // from "<" | "=" | ">" | "≠" | "≤" | "≥" | "☍"
  // to "<" | "<=" | "=" | "!=" | ">=" | ">" | "=*" | "!*" | "^" | "!^" | "$" | "!$"

  switch (op) {
    case "<":
    case ">":
    case "=":
    case "^":
    case "$":
    case "∅":
    case "*":
      return op;
    case "≤":
      return "<=";
    case "≥":
      return ">=";
    case "≠":
      return "!=";
    case "~":
      return "=*";
    default:
      // TODO unsupported params
      return "=";
  }
};
