import React from "react";

import ButtonMultiGroup, { ButtonMultiGroupProps } from "./ButtonMultiGroup";

interface ButtonGroupProps<T> extends Omit<ButtonMultiGroupProps<T>, "active"> {
  active?: T;
}

export default function ButtonGroup<T extends string | number>(
  props: ButtonGroupProps<T>
) {
  const { active } = props;
  return <ButtonMultiGroup {...props} active={active ? [active] : undefined} />;
}
