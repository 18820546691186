import React from "react";

import Card from "../../../components/Card";

interface ErrorCardProps {
  image: string;
  title: string;
  subtitle: string;
  children: React.ReactNode;
}

export default function ErrorCard(props: ErrorCardProps): JSX.Element {
  const { image, title, subtitle, children } = props;

  return (
    <section className="w-full flex flex-col items-center">
      <img src={image} className="w-48 m-2 mt-8" />
      <Card className="flex w-full sm:w-2/3 md:w-1/2 lg:w-1/3">
        <div>
          <h1 className="text-xxl">{title}</h1>
          <h2 className="text-slate-400 font-normal">{subtitle}</h2>
          <div className="my-3 space-y-3 text-base md:text-sm text-slate-700">
            {children}
          </div>
        </div>
      </Card>
    </section>
  );
}
