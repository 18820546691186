import { useSearchParams } from "react-router-dom";

import Filter from "../../../classes/Filter";
import { FilterSetting } from "../../../models/primitives";

export default function useFilterChange(endpointKey = "") {
  const setSearchParams = useSearchParams()[1];

  const handleFilterChange = (filter: FilterSetting[]) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      const newFilter = Filter.stringify(filter);
      newParams.delete(`${endpointKey}Filter`);
      if (newFilter) {
        newParams.set(`${endpointKey}Filter`, newFilter);
      }

      return newParams;
    });
  };

  return handleFilterChange;
}
