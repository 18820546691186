import React from "react";
import { Navigate, RouteObject } from "react-router";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getSolutionPath } from "./solutions";

const ResultDashboard = lazyWithRetry(
  () => import("../pages/Result/ResultDashboard")
);
const ResultSegment = lazyWithRetry(
  () => import("../pages/Result/ResultSegment")
);
const Results = lazyWithRetry(() => import("../pages/Results/Results"));
const ResultsComparison = lazyWithRetry(
  () => import("../pages/ResultsComparison/ResultsComparison")
);

const OverviewTab = lazyWithRetry(
  () => import("../pages/ResultsComparison/tabs/OverviewTab")
);
const SettingsTab = lazyWithRetry(
  () => import("../pages/ResultsComparison/tabs/SettingsTab")
);

const SegmentsComparison = lazyWithRetry(
  () => import("../pages/ResultsComparison/SegmentsComparison")
);

const ChartTab = lazyWithRetry(
  () => import("../pages/ResultsComparison/tabs/ChartTab")
);
const TableTab = lazyWithRetry(
  () => import("../pages/ResultsComparison/tabs/TableTab")
);
const StatisticsTab = lazyWithRetry(
  () => import("../pages/ResultsComparison/tabs/StatisticsTab")
);

const RESULTS_PATH = "results";
const COMPARISON_PATH = "comparison";

export const resultsRoute: RouteObject = {
  path: RESULTS_PATH,
  children: [
    { index: true, element: <Results /> },
    {
      path: COMPARISON_PATH,
      children: [
        { index: true, element: <Navigate to=".." replace /> },
        {
          path: ":runConfigId/:ids",
          element: <ResultsComparison />,
          children: [
            { index: true, element: <Navigate to="./overview" replace /> },
            { path: "overview", element: <OverviewTab /> },
            {
              path: "segments/:measurementId/:partitionId",
              element: <SegmentsComparison />,
              children: [
                { index: true, element: <Navigate to="./chart" replace /> },
                { path: "chart", element: <ChartTab /> },
                { path: "table", element: <TableTab /> },
                { path: "statistics", element: <StatisticsTab /> },
              ],
            },
            { path: "settings", element: <SettingsTab /> },
          ],
        },
      ],
    },
    {
      path: ":runResultId",
      children: [
        { index: true, element: <ResultDashboard /> },
        { path: ":dataSegmentResultId", element: <ResultSegment /> },
      ],
    },
  ],
};

export function getResultsPath(solutionId: string | number): string {
  return `${getSolutionPath(solutionId)}/${RESULTS_PATH}`;
}

export function getResultsComparisonPath(solutionId: string | number): string {
  return `${getResultsPath(solutionId)}/${COMPARISON_PATH}`;
}

export function getResultDetailPath(
  solutionId: string | number,
  runResultId: string | number
): string {
  return `${getResultsPath(solutionId)}/${runResultId}`;
}

export function getResultSegmentDetailPath(
  solutionId: string | number,
  runResultId: string | number,
  dataSegmentResultId: string | number
): string {
  return `${getResultDetailPath(
    solutionId,
    runResultId
  )}/${dataSegmentResultId}`;
}
