import { useSearchParams } from "react-router-dom";

import { stringifyOrderBy } from "../../../api";
import { SortSetting } from "../../../models/primitives";

export default function useSortChange(endpointKey = "") {
  const setSearchParams = useSearchParams()[1];

  const handleSortChange = (sort: SortSetting[]) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set(`${endpointKey}OrderBy`, stringifyOrderBy(sort));
      return newParams;
    });
  };

  return handleSortChange;
}
