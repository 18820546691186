import React from "react";

import classNames from "classnames";

import { useTableContext } from "./Table";

export type CellShrinkStrategy = "wrap" | "truncate" | "none";

export interface TableCellProps {
  className?: string;
  colSpan?: number;
  rowSpan?: number;
  children?: React.ReactNode;
  as?: "td" | "th";
  noPadding?: boolean;
  shrink?: CellShrinkStrategy;
  title?: string;
  onClick?(): void;
}

export const TABLE_CELL_LEFT_PADDING = "pl-5 sm:pl-1";
export const TABLE_CELL_PADDING = `py-4 pr-2 ${TABLE_CELL_LEFT_PADDING}`;
export const TABLE_CELL_FONT = "text-sm";

export const BORDERED_TABLE_CELL_LEFT_PADDING = "pl-2 sm:pl-2";
export const BORDERED_TABLE_CELL_PADDING = `py-2 pr-2 sm:pr-2 ${BORDERED_TABLE_CELL_LEFT_PADDING}`;

export default function TableCell(props: TableCellProps): JSX.Element {
  const { as = "td", children, className = "", noPadding = false } = props;
  const { colSpan, rowSpan, shrink, title } = props;
  const { onClick } = props;

  const { bordered } = useTableContext();

  switch (as) {
    case "td":
      return (
        <td
          colSpan={colSpan}
          rowSpan={rowSpan}
          onClick={onClick}
          className={classNames(
            noPadding
              ? "p-0"
              : bordered
              ? BORDERED_TABLE_CELL_PADDING
              : TABLE_CELL_PADDING,
            getShrinkCSS(shrink),
            TABLE_CELL_FONT,
            "font-normal text-black",
            className
          )}
          title={title}
        >
          {children}
        </td>
      );
    case "th":
      return (
        <th
          colSpan={colSpan}
          rowSpan={rowSpan}
          onClick={onClick}
          scope="col"
          className={classNames(
            !noPadding
              ? bordered
                ? BORDERED_TABLE_CELL_PADDING
                : TABLE_CELL_PADDING
              : "",
            TABLE_CELL_FONT,
            "text-left font-semibold text-gray-900 align-top",
            className
          )}
          title={title}
        >
          {children}
        </th>
      );
  }
}

function getShrinkCSS(shrink?: CellShrinkStrategy): string {
  switch (shrink) {
    case "truncate":
      return "truncate";
    case "wrap":
      return "";
    default:
      return "whitespace-nowrap";
  }
}
