import React from "react";
import { useTranslation } from "react-i18next";

import Number from "./formatter/Number";

interface PaginationLabelProps {
  className?: string;
  from: number;
  to: number;
  total: number;
}

export default function PaginationLabel(
  props: PaginationLabelProps
): JSX.Element {
  const { t } = useTranslation();
  const { from, to, total, className = "" } = props;

  // TODO translate

  return (
    <p className={`text-gray-700 ${className}`}>
      {t("Results {{from}} to {{to}} of {{total}}.", {
        from: from,
        to: to,
        total: total,
      })}
    </p>
  );
}
