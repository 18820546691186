import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useMutation, useQueryClient } from "@tanstack/react-query";

import api, { EndpointOptions, getQueryString } from "../../../api";
import {
  ExportConfig,
  ImportExportConfig,
  ImportExportConfigZod,
} from "../models/importExport";
import { PaginatedResponse, paginatedResponse } from "../models/response";
import {
  CompleteUpload,
  UploadLinkContainer,
  UploadLinkContainerZod,
} from "../models/upload";
import { activeExportTasksQuery } from "./tasks";

async function getImportExportConfig(
  importExportConfigId: string | number
): Promise<ImportExportConfig> {
  return ImportExportConfigZod.parse(
    (await api.get(`/ImportExportConfigs/${importExportConfigId}`)).data
  );
}

export const importExportConfigQuery = (
  importExportConfigId: string | number
) => ({
  queryKey: ["importExportConfig", `${importExportConfigId}`],
  queryFn: () => getImportExportConfig(importExportConfigId),
});

async function getImportExportConfigs(
  solutionId: string,
  options: EndpointOptions = {}
): Promise<PaginatedResponse<ImportExportConfig[]>> {
  const query = getQueryString(options);
  const resultsQ = paginatedResponse(ImportExportConfigZod.array()).parse(
    (await api.get(`/Solutions/${solutionId}/ImportExportConfigs?${query}`))
      .data
  );
  return resultsQ;
}

async function updateImportExportConfig(
  importExportConfigId: string,
  patch: Partial<ImportExportConfig>
) {
  return ImportExportConfigZod.parse(
    (await api.patch(`/ImportExportConfigs/${importExportConfigId}`, patch))
      .data
  );
}

export const useEditImportExportConfig = (
  solutionId: string,
  importExportConfigId: string
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (patch: Partial<ImportExportConfig>) =>
      updateImportExportConfig(importExportConfigId, patch),
    onSuccess: (newDataset) => {
      queryClient.setQueryData(
        ["importExportConfig", importExportConfigId],
        newDataset
      );
      queryClient.invalidateQueries({
        queryKey: ["importExportConfigs", solutionId],
      });
    },
    onError: () => {
      toast.error(t("An error has occurred. Please try again."));
    },
  });
};

export const resultExportsQuery = (solutionId: string, resultId: string) => ({
  queryKey: ["importExportConfigs", solutionId, "resultExports", resultId],
  queryFn: () =>
    getImportExportConfigs(solutionId, {
      filter: [
        ["entity", "=", ["Result", "IntradayProfiles"]],
        ["operation", "=", "Export"],
        ["resultId", "=", resultId],
      ],
      sort: [["finishedAt", "desc"]],
    }),
});

export const factorsImportsQuery = (
  solutionId: string,
  options?: EndpointOptions
) => ({
  queryKey: [
    "importExportConfigs",
    solutionId,
    "factorsImports",
    ...(options ? [options] : []),
  ],
  queryFn: () =>
    getImportExportConfigs(solutionId, {
      ...options,
      filter: [
        ["entity", "=", "InfluencingFactors"],
        ["operation", "=", "Import"],
        ...(options?.filter ?? []),
      ],
    }),
});

export const businessHoursImportsQuery = (
  solutionId: string,
  options?: EndpointOptions
) => ({
  queryKey: [
    "importExportConfigs",
    solutionId,
    "businessHoursImports",
    ...(options ? [options] : []),
  ],
  queryFn: () =>
    getImportExportConfigs(solutionId, {
      ...options,
      filter: [
        ["entity", "=", "BusinessHours"],
        ["operation", "=", "Import"],
        ...(options?.filter ?? []),
      ],
    }),
});

async function startExport(
  solutionId: string,
  props: ExportConfig
): Promise<ImportExportConfig> {
  return ImportExportConfigZod.parse(
    (await api.post(`/Solutions/${solutionId}/Export`, props)).data
  );
}

export const useStartExport = (solutionId: string, resultId?: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (config: ExportConfig) => startExport(solutionId, config),
    onSuccess: () => {
      if (resultId) {
        queryClient.invalidateQueries(
          activeExportTasksQuery(solutionId, "ExportResult")
        );
        queryClient.invalidateQueries(resultExportsQuery(solutionId, resultId));
      }
      queryClient.invalidateQueries(
        activeExportTasksQuery(solutionId, "ExportBusinessHours")
      );
      queryClient.invalidateQueries(
        activeExportTasksQuery(solutionId, "ExportInfluencingFactors")
      );
    },
    onError: () => {
      toast.error(t("An error has occurred. Please try again."));
    },
  });
};

async function createImportExportConfig(
  solutionId: string,
  importExportConfig: ImportExportConfig
): Promise<ImportExportConfig> {
  return ImportExportConfigZod.parse(
    (
      await api.post(
        `/Solutions/${solutionId}/ImportExportConfigs`,
        importExportConfig
      )
    ).data
  );
}

export const useCreateImportExportConfig = (solutionId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (importExportConfig: ImportExportConfig) =>
      createImportExportConfig(solutionId, importExportConfig),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["importExportConfigs"] });
    },
    onError: () => {
      toast.error(t("An error occurred while saving. Please try again."));
    },
  });
};

export async function getEntityUploadContainer(
  importExportConfigId: number | string,
  fileName: string,
  fileSize: number
): Promise<UploadLinkContainer> {
  return UploadLinkContainerZod.parse(
    (
      await api.get(
        `/ImportExportConfigs/${importExportConfigId}/UploadLink?fileName=${fileName}&fileSize=${fileSize}`
      )
    ).data
  );
}

export async function completeEntityUpload(
  importExportConfigId: number | string,
  data: CompleteUpload
) {
  return api.post(
    `ImportExportConfigs/${importExportConfigId}/CompleteUpload`,
    data
  );
}

async function startEntityImport(importExportConfigId: string | number) {
  return api.post(`/ImportExportConfigs/${importExportConfigId}/StartTask`);
}

export const useStartEntityImport = (solutionId: string | number) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (importExportConfigId: string | number) =>
      startEntityImport(importExportConfigId),
    onSuccess: () => {
      queryClient.invalidateQueries(businessHoursImportsQuery(`${solutionId}`));
      queryClient.invalidateQueries(factorsImportsQuery(`${solutionId}`));
    },
    onError: () => {
      toast.error(t("An error has occurred. Please try again."));
    },
  });
};

async function deleteImportExportConfig(importExportConfigId: number | string) {
  return api.delete(`/ImportExportConfigs/${importExportConfigId}`);
}

export const useDeleteImportExportConfig = (
  solutionId: string,
  silent = false
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (importExportConfigId: number | string) =>
      deleteImportExportConfig(importExportConfigId),
    onSuccess: (_, importExportConfigId) => {
      if (!silent) {
        toast.success(t("Entry deleted successfully."));
      }
      queryClient.invalidateQueries({
        queryKey: ["importExportConfigs", solutionId],
      });
      queryClient.removeQueries(importExportConfigQuery(importExportConfigId));
    },
    onError: () => {
      toast.error(t("An error occurred while deleting. Please try again."));
    },
  });
};

async function deleteImportExportConfigsByIds(ids: number[]) {
  return api.delete(`/ImportExportConfigs/Batch`, { data: { ids } });
}

export const useDeleteImportExportConfigsByIds = (solutionId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: deleteImportExportConfigsByIds,
    onSuccess: (_, ids) => {
      toast.success(t("Entries deleted successfully."));
      queryClient.invalidateQueries({
        queryKey: ["importExportConfigs", solutionId],
      });
      queryClient.removeQueries(
        ...ids.map((id) => importExportConfigQuery(id))
      );
    },
    onError: () => {
      toast.error(t("An error occurred while deleting. Please try again."));
    },
  });
};

async function deleteImportExportConfigsByFilter(
  solutionId: string,
  options: EndpointOptions
) {
  const query = getQueryString(options);
  return api.delete(`/Solutions/${solutionId}/ImportExportConfigs?${query}`);
}

export const useDeleteImportExportConfigsByFilter = (solutionId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (options: EndpointOptions) =>
      deleteImportExportConfigsByFilter(solutionId, options),
    onSuccess: () => {
      toast.success(t("Entries deleted successfully."));
      queryClient.invalidateQueries({
        queryKey: ["importExportConfigs", solutionId],
      });
    },
    onError: () => {
      toast.error(t("An error occurred while deleting. Please try again."));
    },
  });
};
