import { useSearchParams } from "react-router-dom";

export default function usePageChange(endpointKey = "") {
  const setSearchParams = useSearchParams()[1];

  const handlePageChange = (page: number) => {
    setSearchParams((prev) => {
      const newParams = new URLSearchParams(prev);
      newParams.set(`${endpointKey}Page`, page.toString());
      return newParams;
    });
  };

  return handlePageChange;
}
