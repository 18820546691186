import React from "react";

import useGeneralMenuItems from "../../hooks/useGeneralMenuItems";
import Sidebar from "../sidebar/Sidebar";
import BasicLayout from "./BasicLayout";

interface GeneralLayoutProps {
  children?: React.ReactNode;
}

export default function GeneralLayout({ children }: GeneralLayoutProps) {
  const [topNavigation, bottomNavigation] = useGeneralMenuItems();

  return (
    <Sidebar
      topNavigation={[topNavigation]}
      bottomNavigation={bottomNavigation}
    >
      <BasicLayout>{children}</BasicLayout>
    </Sidebar>
  );
}
