import React from "react";
import { RouteObject } from "react-router";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getSolutionPath, getSolutionsPath } from "./solutions";

const TaskDetail = lazyWithRetry(
  () => import("../pages/TaskDetail/TaskDetail")
);
const TasksOverview = lazyWithRetry(
  () => import("../pages/TasksOverview/TasksOverview")
);

const TASKS_PATH = "tasks";

export const tasksRoute: RouteObject = {
  path: TASKS_PATH,
  children: [
    { index: true, element: <TasksOverview /> },
    { path: ":taskId", element: <TaskDetail /> },
  ],
};

export function getTasksPath(solutionId: string | number | undefined): string {
  if (!solutionId) {
    return `${getSolutionsPath()}/${TASKS_PATH}`;
  }

  return `${getSolutionPath(solutionId)}/${TASKS_PATH}`;
}

export function getTaskDetailPath(
  solutionId: string | number | undefined,
  taskId: string
): string {
  return `${getTasksPath(solutionId)}/${taskId}`;
}
