import React from "react";
import DatePicker from "react-datepicker";
import { AiOutlineCaretLeft, AiOutlineCaretRight } from "react-icons/ai";

import useSettings from "../hooks/useSettings";
import { DateFormat } from "../models/settings";
import ErrorBoundary from "./ErrorBoundary";
import { CustomDatePickerHeader } from "./form/CustomDatePickerHeader";

type FormattedDatePickerProps = DatePicker["props"];

export default function FormattedDatePicker(props: FormattedDatePickerProps) {
  const settings = useSettings();
  const dateFormat = transformDateFormat(settings.dateFormat);

  return (
    <ErrorBoundary>
      <DatePicker
        dateFormat={dateFormat}
        previousMonthButtonLabel={<AiOutlineCaretLeft />}
        nextMonthButtonLabel={<AiOutlineCaretRight />}
        calendarStartDay={1}
        showPopperArrow={false}
        renderCustomHeader={(props) => <CustomDatePickerHeader {...props} />}
        {...props}
      />
    </ErrorBoundary>
  );
}

/**
 * Transform d3 date format (%d %m %Y) to dd MM yyyy format.
 * @param format d3 date format string
 * @returns dd MM yyyy format string
 */
const transformDateFormat = (format: DateFormat) => {
  return format.replace("%Y", "yyyy").replace("%m", "MM").replace("%d", "dd");
};
