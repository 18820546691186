import React from "react";

export type TooltipProps = {
  children: React.ReactNode;
  text: string;
};

export default function Tooltip(props: TooltipProps): JSX.Element {
  return (
    <span className="group relative cursor-pointer">
      <span
        className={`pointer-events-none absolute \
                    -top-2 left-1/2 -translate-x-1/2 -translate-y-full \
                    rounded bg-white px-2 py-1 text-black text-sm font-normal shadow \
                    opacity-0 transition group-hover:opacity-100 \
                    whitespace-nowrap \
                    before:absolute before:left-1/2 before:top-full before:-translate-x-1/2 before:border-4 before:border-transparent before:border-t-gray-200 before:content-[''] \
                    `}
      >
        {props.text}
      </span>
      {props.children}
    </span>
  );
}
