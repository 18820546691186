import React from "react";

import useDebounce from "../hooks/useDebounce";
import { TextFieldProps } from "./form/TextField";

interface DebouncedWrapperProps {
  children(renderProps: {
    value: TextFieldProps["value"];
    onChange: (value: string) => void;
    error?: string | boolean | undefined;
  }): JSX.Element;
  delay?: number;
  value: TextFieldProps["value"];
  onChange(value: string): void;
  generateError?(value: TextFieldProps["value"]): string | boolean | undefined;
}

export default function DebouncedWrapper(
  props: DebouncedWrapperProps
): JSX.Element {
  const { children, value, onChange, generateError, delay = 300 } = props;

  const [innerValue, setInnerValue] = React.useState(value);
  React.useEffect(() => {
    handleValueChange(value);
  }, [value]);
  const [innerError, setInnerError] = React.useState<string | boolean>();

  const handleValueChange = (newValue: TextFieldProps["value"]): boolean => {
    setInnerValue(newValue);
    if (generateError) {
      const generatedError = generateError(newValue);
      setInnerError(generatedError);
      if (generatedError) {
        return false;
      }
    }
    return true;
  };

  const handleDebouncedChange = useDebounce((newValue: string) => {
    if (typeof innerError === "undefined" || innerError === false) {
      onChange(newValue);
    }
  }, delay);

  const handleChange = (newValue: string) => {
    const isValid = handleValueChange(newValue);
    if (isValid) {
      handleDebouncedChange(newValue);
    }
  };

  return children({
    value: innerValue,
    onChange: handleChange,
    error: innerError,
  });
}
