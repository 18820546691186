import { z } from "zod";

import { AggregateZod } from "../../../models/aggregate";
import { DateTimeZod } from "../../../models/primitives";
import { ImportExportZoneZod, PreviewRowZod } from "./dataset";
import {
  GeneralImportModeZod,
  ImportExportFileTypeZod,
  ImportExportSeparatorZod,
} from "./file";
import { TaskStatusZod, TaskSubStatusZod } from "./task";

const importExportOperations = ["Import", "Export"] as const;
const ImportExportOperationZod = z.enum(importExportOperations);

const importExportEntities = [
  "Result",
  "IntradayProfiles",
  "BusinessHours",
  "InfluencingFactors",
  "History",
] as const;
const ImportExportEntityZod = z.enum(importExportEntities);
export type ImportExportEntity = z.infer<typeof ImportExportEntityZod>;

export const ImportExportConfigZod = z.object({
  importExportConfigId: z.number(),
  operation: ImportExportOperationZod,
  entity: ImportExportEntityZod,
  fileType: ImportExportFileTypeZod.nullable().optional(),
  columnSeparator: ImportExportSeparatorZod.nullable().optional(),
  fileName: z.string(),
  mode: GeneralImportModeZod.nullable().optional(),
  resultTimeScale: AggregateZod.nullable().optional(),
  resultId: z.number().nullable().optional(),
  includeHistory: z.boolean().nullable().optional(),
  createdAt: DateTimeZod.nullable().optional(),
  finishedAt: DateTimeZod.nullable().optional(),
  downloadUrl: z.string().nullable().optional(),
  status: TaskStatusZod.nullable().optional(),
  subStatus: TaskSubStatusZod.nullable().optional(),
  nrRows: z.number().nullable().optional(),
  size: z.number().nullable().optional(),
  problem: z.string().nullable().optional(),
  columns: z.string().array().nullable().optional(),
  firstRows: PreviewRowZod.array().nullable().optional(),
  lastRows: PreviewRowZod.array().nullable().optional(),
  timestampZone: ImportExportZoneZod,
  includeAggregates: z.boolean(),
});

export type ImportExportConfig = z.infer<typeof ImportExportConfigZod>;

export const ExportConfigZod = ImportExportConfigZod.pick({
  entity: true,
  fileType: true,
  columnSeparator: true,
  fileName: true,
  resultTimeScale: true,
  resultId: true,
  includeHistory: true,
  timestampZone: true,
});
export type ExportConfig = z.infer<typeof ExportConfigZod>;

export type GeneralUploadProps = {
  file: File | null;
  isUploading: boolean;
  uploadProgress: number | undefined;
  onUpload(uploadedFile: File | null): Promise<void>;
  onFileChange(newFile: File | null): void;
};
