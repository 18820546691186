import React from "react";

import ButtonGroup from "../../../components/buttons/ButtonGroup";
import { Aggregate } from "../../../models/aggregate";
import { Invalid } from "../../../models/primitives";

interface LargeTimescaleButtonsProps {
  id: string;
  label?: string;
  options: Aggregate[];
  value: Aggregate | Invalid | null;
  disabled?: boolean;
  onChange: (scale: Aggregate) => void;
}

export default function LargeTimescaleButtons(
  props: LargeTimescaleButtonsProps
) {
  const { id, label, options, value, disabled, onChange } = props;

  const realValue = value !== "Invalid" ? value : null;

  return (
    <div>
      {label && (
        <label
          htmlFor={id}
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          {label}
        </label>
      )}
      <ButtonGroup
        id={id}
        wrapperClassName={"w-full"}
        options={options.map((key) => ({ key }))}
        active={realValue ?? undefined}
        disabled={disabled}
        onClick={onChange}
      />
    </div>
  );
}
