import React from "react";

import env from "../services/env";

const WebSocketContext = React.createContext<WebSocket | null>(null);

const DEFAULT_RECONNECT_DELAY = 500;

if (!env.WS_URL) {
  throw new Error("WS_URL parameter not specified in .env!");
}

const endpoint = env.WS_URL.match(/wss?:\/\//)
  ? env.WS_URL
  : `wss://${window.location.host}${env.WS_URL}`;

function getWebSocketConnection(
  delayRef: React.MutableRefObject<number>
): WebSocket {
  const connection: WebSocket = new WebSocket(endpoint);
  connection.onopen = function () {
    console.log(`WebSocket opened successfully on ${endpoint}.`);
    delayRef.current = DEFAULT_RECONNECT_DELAY;
  };
  connection.onerror = function (error) {
    console.error("WebSocket failed unexpectedly. Closing the socket.", error);
    connection.close();
  };

  return connection;
}

interface WebSocketProviderProps {
  children: React.ReactNode;
}

export default function WebSocketProvider(
  props: WebSocketProviderProps
): JSX.Element {
  const { children } = props;

  const timeoutRef = React.useRef<NodeJS.Timeout>();
  const delayRef = React.useRef(DEFAULT_RECONNECT_DELAY);
  const [connection, setConnection] = React.useState<WebSocket>(
    getWebSocketConnection(delayRef)
  );
  React.useEffect(() => {
    const onClose = () => {
      console.warn(
        `WebSocket closed. Reconnecting in ${delayRef.current / 1000} seconds.`
      );
      timeoutRef.current = setTimeout(() => {
        setConnection(getWebSocketConnection(delayRef));
      }, delayRef.current);
      delayRef.current = delayRef.current * 2;
    };

    connection.addEventListener("close", onClose);

    return () => {
      connection.removeEventListener("close", onClose);
    };
  }, [connection, setConnection]);
  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  return (
    <WebSocketContext.Provider value={connection}>
      {children}
    </WebSocketContext.Provider>
  );
}

export function useWebSocket(): WebSocket {
  const connection = React.useContext(WebSocketContext);
  if (!connection) {
    throw new Error(`WebSocket connection not established. URL: ${endpoint}`);
  }
  return connection;
}
