import React from "react";
import { useTranslation } from "react-i18next";

import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";

import HeroActionButton from "../../../components/HeroActionButton";
import LargeTimescaleButtons from "../../../components/LargeTimescaleButtons";
import Slideover from "../../../components/Slideover";
import ListCheckbox from "../../../components/form/ListCheckbox";
import { ImportExportConfig } from "../../../models/importExport";
import { SolutionDetail } from "../../../models/solution";
import EntityExportButton from "../../BusinessHours/components/EntityExportButton";
import StandardExportButton from "../../BusinessHours/components/StandardExportButton";
import TimestampZonePicker from "../../Dataset/components/TimestampZonePicker";

interface HistoryExportButtonProps {
  solution: SolutionDetail;
  type: "hero" | "standard";
  className?: string;
}

type HistoryExportState = Pick<
  ImportExportConfig,
  "timestampZone" | "resultTimeScale" | "includeAggregates"
>;

export default function HistoryExportButton(
  props: HistoryExportButtonProps
): JSX.Element {
  const { solution, className, type } = props;

  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const [state, setState] = React.useState<HistoryExportState>({
    timestampZone: "Local",
    resultTimeScale: "1D",
    includeAggregates: false,
  });

  const handleClick = () => {
    setOpen(true);
  };

  const title = t("Export history data");

  return (
    <>
      {type === "hero" && (
        <HeroActionButton
          title={title}
          onClick={handleClick}
          className={className}
          disabled={open}
        >
          <ArrowDownTrayIcon />
        </HeroActionButton>
      )}
      {type === "standard" && (
        <StandardExportButton
          className={className}
          onClick={handleClick}
          disabled={open}
        >
          {title}
        </StandardExportButton>
      )}
      <Slideover
        title={t("History export settings")}
        open={open}
        onClose={() => setOpen(false)}
      >
        <div className="space-y-4">
          <div>
            <TimestampZonePicker
              id="timestampZone"
              label={t("Time zone")}
              solution={solution}
              value={state.timestampZone}
              onChange={(timestampZone) =>
                setState((prev) => ({ ...prev, timestampZone }))
              }
            />
          </div>
          <div>
            <LargeTimescaleButtons
              id="scaleButtons"
              label={t("Time scale")}
              options={solution.aggregates}
              value={state.resultTimeScale ?? null}
              onChange={(resultTimeScale) =>
                setState((prev) => ({ ...prev, resultTimeScale }))
              }
            />
          </div>
          <div className="py-2">
            <ListCheckbox
              options={[
                {
                  key: "includeAggregates",
                  value: "includeAggregates",
                  label: t("Include planning area aggregates"),
                },
              ]}
              value={
                state.includeAggregates
                  ? [{ key: "includeAggregates", value: "includeAggregates" }]
                  : []
              }
              onChange={(values) =>
                setState((prev) => ({
                  ...prev,
                  includeAggregates: values.some(
                    (v) => v.key === "includeAggregates"
                  ),
                }))
              }
            />
          </div>
          <div className="text-right">
            <EntityExportButton
              entity="History"
              type="standard"
              variant="primary"
              config={state}
              hideIcon
            />
          </div>
        </div>
      </Slideover>
    </>
  );
}
