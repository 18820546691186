import React from "react";
import { RouteObject } from "react-router";

import { z } from "zod";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getSolutionPath } from "./solutions";

const Dataset = lazyWithRetry(() => import("../pages/Dataset/Dataset"));
const DatasetSource = lazyWithRetry(
  () => import("../pages/Dataset/sections/DatasetSource")
);
const DatasetMappings = lazyWithRetry(
  () => import("../pages/Dataset/sections/DatasetMappings")
);
const DatasetTimestampFormat = lazyWithRetry(
  () => import("../pages/Dataset/sections/DatasetTimestampFormat")
);
const DatasetAggregateFun = lazyWithRetry(
  () => import("../pages/Dataset/sections/DatasetAggregateFun")
);
const DatasetImportSettings = lazyWithRetry(
  () => import("../pages/Dataset/sections/DatasetImportSettings")
);
const Import = lazyWithRetry(() => import("../pages/Import/Import"));

const IMPORT_PATH = "import";

export const datasetPages = [
  "source",
  "mapping",
  "timestamps",
  "aggregates",
  "import",
] as const;
export const DatasetPageZod = z.enum(datasetPages);
export type DatasetPage = z.infer<typeof DatasetPageZod>;

const PageEnum = DatasetPageZod.Enum;

export const importsRoute: RouteObject = {
  path: IMPORT_PATH,
  children: [
    { index: true, element: <Import /> },
    {
      path: ":datasetId",
      element: <Dataset />,
      children: [
        { path: PageEnum.source, element: <DatasetSource /> },
        { path: PageEnum.mapping, element: <DatasetMappings /> },
        { path: PageEnum.timestamps, element: <DatasetTimestampFormat /> },
        { path: PageEnum.aggregates, element: <DatasetAggregateFun /> },
        { path: PageEnum.import, element: <DatasetImportSettings /> },
      ],
    },
  ],
};

export function getImportsPath(solutionId: string | number): string {
  return `${getSolutionPath(solutionId)}/${IMPORT_PATH}`;
}

export function getImportDetailPath(
  solutionId: string | number,
  datasetId: string | number
): string {
  return `${getImportsPath(solutionId)}/${datasetId}`;
}
