import React from "react";

import {
  type QueryKey,
  UseQueryOptions,
  useQuery,
} from "@tanstack/react-query";

import { EndpointOptions } from "../../../api";
import { FilterSetting } from "../../../models/primitives";
import useEndpointOptions from "./useEndpointOptions";
import useFilterChange from "./useFilterChange";
import usePageChange from "./usePageChange";
import useSortChange from "./useSortChange";

type TanstackQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = Omit<
  UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  "initialData"
> & { initialData?: () => undefined };

export default function useEndpoint<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  query:
    | TanstackQuery<TQueryFnData, TError, TData, TQueryKey>
    | ((
        options: EndpointOptions
      ) => TanstackQuery<TQueryFnData, TError, TData, TQueryKey>),
  defaultOptions?: EndpointOptions
) {
  const [lastCount, setLastCount] = React.useState<number | null>(null);
  const options = useEndpointOptions(defaultOptions);
  const appliedOptions = useAppliedEndpointOptions(options, lastCount);

  if (typeof query === "function") {
    query = query(appliedOptions);
  }

  const queryResult = useQuery(query);

  const currentCount =
    queryResult.data &&
    typeof queryResult.data === "object" &&
    "count" in queryResult.data &&
    typeof queryResult.data.count === "number"
      ? queryResult.data.count
      : null;
  React.useEffect(() => {
    if (lastCount !== currentCount) {
      setLastCount(currentCount);
    }
  }, [setLastCount, currentCount]);

  const onSortChange = useSortChange(options.endpointKey);
  const onFilterChange = useFilterChange(options.endpointKey);
  const onPageChange = usePageChange(options.endpointKey);

  return {
    queryResult,
    options,
    appliedOptions,
    onSortChange,
    onFilterChange,
    onPageChange,
  };
}

export function useAppliedEndpointOptions(
  options: EndpointOptions,
  lastCount: number | null
): EndpointOptions {
  const applied: EndpointOptions = { ...options };

  applied.filter = applied.filter
    // filter non-empty (or (non)empty if operator is ∅/*)
    ?.filter((o) => o[2] !== "" || o[1] === "∅" || o[1] === "*")
    // <= operator special rule: don't apply if "undefined" (during range definition)
    .filter((o) => o[1] !== "<=" || o[2] !== "undefined")
    // ∅ operator special rule: translate it to gridify
    .map((o) => (o[1] !== "∅" ? o : ([o[0], "=", ""] satisfies FilterSetting)))
    // * operator special rule: translate it to gridify
    .map((o) =>
      o[1] !== "*" ? o : ([o[0], "!=", ""] satisfies FilterSetting)
    );

  if (
    lastCount !== null &&
    applied.page &&
    applied.page > 1 &&
    applied.pageSize
  ) {
    const firstItemIdx = (applied.page - 1) * applied.pageSize;
    if (firstItemIdx >= lastCount) {
      applied.page = Math.ceil(lastCount / applied.pageSize);
    }
  }

  return applied;
}
