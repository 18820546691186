import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { QueueListIcon, UserIcon } from "@heroicons/react/24/outline";

import { getSolutionsPath } from "../../routes/solutions";
import { getUserSettingsPath } from "../../routes/userSettings";
import Sidebar, { SidebarItem } from "../sidebar/Sidebar";
import BasicLayout from "./BasicLayout";

interface DemandGeneralLayoutProps {
  children?: React.ReactNode;
}

export default function DemandGeneralLayout({
  children,
}: DemandGeneralLayoutProps) {
  const [topNavigation, bottomNavigation] = useGeneralMenuItems();

  return (
    <Sidebar
      topNavigation={[topNavigation]}
      bottomNavigation={bottomNavigation}
    >
      <BasicLayout>{children}</BasicLayout>
    </Sidebar>
  );
}

export function useGeneralMenuItems(
  solutionId?: string
): [SidebarItem[], SidebarItem[]] {
  const location = useLocation();
  const { t } = useTranslation();
  const pathPrefix = "/demand" + getSolutionsPath();

  const topNavigation: SidebarItem[] = [
    {
      key: "solutions",
      name: t("Projects"),
      href: pathPrefix,
      icon: QueueListIcon,
      current: location.pathname.endsWith(pathPrefix),
    },
  ];

  const bottomNavigation: SidebarItem[] = [
    {
      key: "userSettings",
      name: t("User settings"),
      href: "/demand" + getUserSettingsPath(solutionId),
      icon: UserIcon,
      current: location.pathname.startsWith(
        "/demand" + getUserSettingsPath(solutionId)
      ),
    },
  ];

  return [topNavigation, bottomNavigation];
}
