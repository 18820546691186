import React from "react";
import { useTranslation } from "react-i18next";

import {
  CheckIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";

import CircularLoading from "../../../components/CircularLoading";
import { AutosaveStatus } from "../hooks/useAutosave";
import { HERO_ACTION_BTN_BASE } from "./HeroActionButton";

interface AutosaveIndicatorProps {
  status: AutosaveStatus;
}

export default function AutosaveIndicator(
  props: AutosaveIndicatorProps
): JSX.Element | null {
  const { status } = props;

  const { content, title } = useIconStyle(status);

  return (
    <span title={title} className={HERO_ACTION_BTN_BASE}>
      {content}
    </span>
  );
}

function useIconStyle(status: AutosaveStatus): {
  content: JSX.Element | null;
  title?: string;
} {
  const { t } = useTranslation();

  switch (status) {
    case "idle":
      return {
        content: <CheckIcon className="text-blue-500" />,
        title: t("all changes saved"),
      };
    case "dirty":
      return { content: null };
    case "pending":
      return {
        content: <CircularLoading className="w-5 h-5 text-blue-500" />,
        title: t("saving") + "...",
      };
    case "invalid":
      return {
        content: <ExclamationTriangleIcon className="text-yellow-500" />,
        title: t("Some fields are not valid"),
      };
    case "error":
      return {
        content: <ExclamationCircleIcon className="text-red-500" />,
        title: t("Error while saving changes"),
      };
    case "retrying":
      return {
        content: (
          <ExclamationCircleIcon className="text-red-500 animate-pulse" />
        ),
        title: t("retrying to save the data") + "...",
      };
  }
}
