import React from "react";
import { RouteObject } from "react-router";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getSolutionPath } from "../../PrognosAI/routes/solutions";

const Analyzer = lazyWithRetry(() => import("../pages/Analyzer/Analyzer"));

const ANALYZER_PATH = "analyzer";

export const analyzerRoute: RouteObject = {
  path: ANALYZER_PATH,
  element: <Analyzer />,
};

export function getAnalyzerPath(solutionId: string | number): string {
  return `/analyzer${getSolutionPath(solutionId)}/${ANALYZER_PATH}`;
}
