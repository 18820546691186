import React from "react";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

import useD3Locale from "../../hooks/useD3Locale";

const PLUS_MINUS_YEARS = 5;

export const CustomDatePickerHeader = (
  props: ReactDatePickerCustomHeaderProps
) => {
  const { date, prevMonthButtonDisabled, nextMonthButtonDisabled } = props;
  const { changeYear, changeMonth, decreaseMonth, increaseMonth } = props;

  const locale = useD3Locale();

  const months = locale.months;

  const year = date.getFullYear();
  const years = [...Array(1 + PLUS_MINUS_YEARS * 2).keys()].map(
    (i) => date.getFullYear() + i - PLUS_MINUS_YEARS
  );

  return (
    <div className="w-full flex justify-between px-0.5 py-2">
      <button
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        type="button"
      >
        <ChevronLeftIcon className="w-5 h-5 text-gray-500" />
      </button>

      <select
        value={date.getMonth()}
        onChange={({ target: { value } }) => changeMonth(parseInt(value))}
        className="font-medium text-base"
      >
        {months.map((option, index) => (
          <option key={option} value={index} className="text-sm">
            {option}
          </option>
        ))}
      </select>

      <select
        value={year}
        onChange={({ target: { value } }) => changeYear(parseInt(value))}
        className="font-medium text-base"
      >
        {years.map((option) => (
          <option key={option} value={option} className="text-sm">
            {option}
          </option>
        ))}
      </select>

      <button
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        type="button"
      >
        <ChevronRightIcon className="w-5 h-5 text-gray-500" />
      </button>
    </div>
  );
};
