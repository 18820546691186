import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import {
  QuestionMarkCircleIcon,
  QueueListIcon,
  RectangleStackIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

import { SidebarItem } from "../components/sidebar/Sidebar";
import { getHelpPath } from "../routes/help";
import { getSolutionsPath } from "../routes/solutions";
import { getTasksPath } from "../routes/tasks";
import { getUserSettingsPath } from "../routes/userSettings";

export default function useGeneralMenuItems(
  solutionId?: string
): [SidebarItem[], SidebarItem[]] {
  const location = useLocation();
  const { t } = useTranslation();
  const pathPrefix = getSolutionsPath();

  const topNavigation: SidebarItem[] = [
    {
      key: "solutions",
      name: t("Projects"),
      href: pathPrefix,
      icon: QueueListIcon,
      current: location.pathname.endsWith(pathPrefix),
    },
  ];

  const bottomNavigation: SidebarItem[] = [
    {
      key: "userSettings",
      name: t("User settings"),
      href: getUserSettingsPath(solutionId),
      icon: UserIcon,
      current: location.pathname.startsWith(getUserSettingsPath(solutionId)),
    },
    {
      key: "help",
      name: t("Help"),
      href: getHelpPath(solutionId),
      icon: QuestionMarkCircleIcon,
      current: location.pathname.startsWith(getHelpPath(solutionId)),
    },
    {
      key: "tasks",
      name: t("System tasks"),
      href: getTasksPath(solutionId),
      icon: RectangleStackIcon,
      current: location.pathname.startsWith(getTasksPath(solutionId)),
    },
  ];

  return [topNavigation, bottomNavigation];
}
