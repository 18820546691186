import React from "react";
import { useTranslation } from "react-i18next";
import { FiChevronRight } from "react-icons/fi";

import Breadcrumb from "./Breadcrumb";

interface BreadcrumbsProps {
  children?: JSX.Element | JSX.Element[];
  root?: string;
  rootLabel?: string;
  hideRoot?: boolean;
}

export default function Breadcrumbs(props: BreadcrumbsProps): JSX.Element {
  const { t } = useTranslation();
  const { root = "/auth", rootLabel = t("Projects"), hideRoot } = props;
  let { children } = props;

  if (children) {
    children = Array.isArray(children)
      ? children
      : ([children] as JSX.Element[]);
  } else {
    children = [];
  }

  return (
    <nav className="text-xs mb-3 px-6 flex flex-wrap items-center">
      {!hideRoot && <Breadcrumb to={root}>{rootLabel}</Breadcrumb>}
      {children.map((child, index) => {
        return (
          <React.Fragment key={index}>
            {(!hideRoot || !!index) && (
              <FiChevronRight className="text-slate-400 mx-1" />
            )}
            {child}
          </React.Fragment>
        );
      })}
    </nav>
  );
}
