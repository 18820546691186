import { z } from "zod";

const ModelRunDependencyZod = z.object({
  id: z.number(),
  name: z.string(),
  rawTypeName: z.literal("RunResult"),
  type: z.literal("RunResult"),
});
export type ModelRunDependency = z.infer<typeof ModelRunDependencyZod>;

export const ModelDeletionErrorZod = z.object({
  id: z.number(),
  name: z.string(),
  rawTypeName: z.literal("PgnModel"),
  type: z.literal("PgnModel"),
  valuesCount: z.number(),
  values: ModelRunDependencyZod.array(),
});

export const RunDeletionErrorZod = z.object({
  id: z.number(),
  name: z.string(),
  rawTypeName: z.literal("RunConfig"),
  type: z.literal("RunConfig"),
  valuesCount: z.number(),
  values: ModelRunDependencyZod.array(),
});

export const datasetDependencyTypes = [
  "RunConfig",
  "Chart",
  "RunResult",
  "BusinessHourSet",
  "InfluencingFactor",
] as const;
const DatasetDependencyTypeZod = z.enum(datasetDependencyTypes);
export type DatasetDependencyType = z.infer<typeof DatasetDependencyTypeZod>;

const DatasetDependencyZod = z.object({
  id: z.number(),
  name: z.string(),
  rawTypeName: z.string(),
  type: DatasetDependencyTypeZod,
});
export type DatasetDependency = z.infer<typeof DatasetDependencyZod>;

export const DatasetImportErrorZod = z.object({
  id: z.number(),
  name: z.string(),
  rawTypeName: z.literal("Dataset"),
  type: z.literal("Dataset"),
  valuesCount: z.number(),
  values: DatasetDependencyZod.array(),
});
export type DatasetImportError = z.infer<typeof DatasetImportErrorZod>;
