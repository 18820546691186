import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

import {
  ArrowRightCircleIcon,
  BriefcaseIcon,
  ChartBarIcon,
  CircleStackIcon,
  Cog6ToothIcon,
  CommandLineIcon,
  PresentationChartLineIcon,
  ScissorsIcon,
  SunIcon,
} from "@heroicons/react/24/outline";

import { HeroIcon } from "../../../../../models/primitives";
import SideMenu from "../../../components/sidemenu/SideMenu";
import SideMenuDivider from "../../../components/sidemenu/SideMenuDivider";
import SideMenuEntry from "../../../components/sidemenu/SideMenuEntry";
import { RunConfigPage, RunConfigPageZod } from "../../../routes/runConfigs";
import { getTaskActionIcon } from "../../Results/utils";

export default function RunMenu(): JSX.Element {
  const { runConfigId } = useParams();
  if (!runConfigId) {
    throw new Error("URL parameter :runConfigId not specified!");
  }

  const pages = usePages();
  const page = useRunConfigPage();

  const { t } = useTranslation();

  return (
    <SideMenu>
      {pages
        .filter((p) => !outsiderPages.includes(p.id))
        .map((item) => (
          <SideMenuEntry
            key={item.id}
            id={item.id}
            name={item.name}
            href={`./${item.id}`}
            icon={item.icon}
            active={page === item.id}
          />
        ))}
      <SideMenuDivider />
      {outsiderPages.map((id) => {
        const item = pages.find((p) => p.id === id);
        if (!item) {
          return null;
        }
        return (
          <SideMenuEntry
            key={item.id}
            id={item.id}
            name={item.name}
            href={`./${item.id}`}
            icon={item.icon}
            active={page === item.id}
          />
        );
      })}
      <SideMenuEntry
        id="runResults"
        name={t("Results")}
        href={`../../results?Filter=runConfigId = ${runConfigId}`}
        icon={PresentationChartLineIcon}
      />
    </SideMenu>
  );
}

export const outsiderPages: RunConfigPage[] = ["models", "fixedModels"];

interface RunNavItem {
  id: RunConfigPage;
  name: string;
  icon: HeroIcon;
}

const usePages = (): RunNavItem[] => {
  const { t } = useTranslation();

  return [
    { id: "general", name: t("General Settings"), icon: Cog6ToothIcon },
    { id: "data", name: t("Data selection"), icon: CircleStackIcon },
    { id: "task", name: t("Task Settings"), icon: BriefcaseIcon },
    { id: "outliers", name: t("Outliers"), icon: ChartBarIcon },
    {
      id: "preprocessing",
      name: t("Pre-processing"),
      icon: ArrowRightCircleIcon,
    },
    { id: "postprocessing", name: t("Post-processing"), icon: ScissorsIcon },
    { id: "intradayProfiles", name: t("Intraday profiles"), icon: SunIcon },
    {
      id: "advanced",
      name: t("JSON definition (Expert user)"),
      icon: CommandLineIcon,
    },
    {
      id: "models",
      name: t("Run on a model"),
      icon: getTaskActionIcon("start"),
    },
    {
      id: "fixedModels",
      name: t("Run on fixed models"),
      icon: getTaskActionIcon("start"),
    },
  ];
};

export const useRunConfigPage = (): RunConfigPage => {
  const location = useLocation();
  const paths = location.pathname.split("/");

  return RunConfigPageZod.catch(RunConfigPageZod.Enum.general).parse(
    paths[paths.length - 1]
  );
};
