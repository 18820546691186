import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";

import api, { EndpointOptions, getQueryString } from "../../../api";
import { ModelRunConfig, ModelRunConfigZod } from "../models/modelRunConfig";
import { PaginatedResponse, paginatedResponse } from "../models/response";
import { ACTIVE_RUN_TASKS_PREFIX } from "./tasks";

async function getModelRunConfigs(
  solutionId: string,
  options: EndpointOptions = {}
): Promise<PaginatedResponse<ModelRunConfig[]>> {
  const query = getQueryString(options);
  return paginatedResponse(ModelRunConfigZod.array()).parse(
    (await api.get(`Solutions/${solutionId}/ModelRunConfigs?${query}`)).data
  );
}

export const modelRunConfigsQuery = (
  solutionId: string,
  options?: EndpointOptions,
  enabled = true
) => ({
  queryKey: ["modelRunConfigs", solutionId, ...(options ? [options] : [])],
  queryFn: () => getModelRunConfigs(solutionId, options),
  enabled,
});

async function createModelRunConfig(
  solutionId: string,
  modelId: number,
  runConfigId: number
): Promise<ModelRunConfig> {
  return ModelRunConfigZod.parse(
    (
      await api.post(`/Solutions/${solutionId}/ModelRunConfigs`, {
        modelId,
        runConfigId,
      })
    ).data
  );
}

export const useSaveModelRunConfig = (solutionId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: ({
      modelId,
      runConfigId,
    }: Pick<ModelRunConfig, "modelId" | "runConfigId">) =>
      createModelRunConfig(solutionId, modelId, runConfigId),
    onSuccess: () => {
      queryClient.invalidateQueries(modelRunConfigsQuery(solutionId));
    },
    onError: () => {
      toast.error(t("An error occurred while saving. Please try again."));
    },
  });
};

async function executeModelRunConfig(modelRunConfigId: string) {
  return api.post(`/ModelRunConfigs/${modelRunConfigId}`);
}

export const useExecuteModelRunConfig = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (modelRunConfigId: number) =>
      executeModelRunConfig(modelRunConfigId.toString()),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ACTIVE_RUN_TASKS_PREFIX });
      queryClient.invalidateQueries({ queryKey: ["results"] });
    },
    onError: (error) => {
      if (error instanceof AxiosError && error.response?.status === 400) {
        const message = error.response.data;
        if (
          message === "No partitions selected" ||
          message === "No measurements selected"
        ) {
          return toast.error(
            t(
              "Cannot start the run. Please first select at least one planning area and observable."
            )
          );
        }
      }
      toast.error(t("Starting the run failed. Please try again."));
    },
  });
};
