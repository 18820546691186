import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import ErrorCard from "../../components/ErrorCard";
import { getSolutionsPath } from "../../routes/solutions";

import lost from "../../images/illustrations/lost.svg";

export default function NotFound(): JSX.Element {
  const { t } = useTranslation();

  return (
    <section className="flex justify-center">
      <ErrorCard title="Error 404" subtitle={t("page not found")} image={lost}>
        <p>{t("The page you are looking for does not exist.")}</p>
        <p>
          {t("Please check the URL for typos or return")}{" "}
          <Link to={getSolutionsPath()}>{t("back to the main page")}</Link>.{" "}
        </p>
      </ErrorCard>
    </section>
  );
}
