import { z } from "zod";

import { BusinessRuleSetZod } from "./businessHours";
import { PartitionerZod } from "./partitioner";

export const PartitionZod = z.object({
  partitionId: z.number(),
  name: z.string(),
  fullName: z.string(),
  partitionerId: z.number().nullable().optional(),
  parentPartitionId: z.number().nullable().optional(),
  isAggregate: z.boolean(),
  referencePartitionId: z.number().nullable().optional(),
});

export type Partition = z.infer<typeof PartitionZod>;

export const PartitionDetailZod = PartitionZod.extend({
  partitioner: PartitionerZod.nullable(),
  parentPartition: PartitionZod.nullable(),
  businessRuleSet: BusinessRuleSetZod.nullable(),
  businessExceptionSets: BusinessRuleSetZod.array(),
});

export type PartitionDetail = z.infer<typeof PartitionDetailZod>;

export const viewModes = ["all", "aggregates", "leaves"] as const;
export const ViewModeZod = z.enum(viewModes);
export type ViewMode = z.infer<typeof ViewModeZod>;

export const PartitionBusinessHoursZod = z.object({
  partitionId: z.number(),
  fullName: z.string(),
  isAggregate: z.boolean(),
  hasBusinessRuleSet: z.boolean(),
  hasBusinessExceptionSet: z.boolean(),
});

export type PartitionBusinessHours = z.infer<typeof PartitionBusinessHoursZod>;
