import React from "react";

import classNames from "classnames";

import NarrowForm from "../../apps/PrognosAI/components/NarrowForm";
import SkeletonCard from "./SkeletonCard";
import SkeletonHero from "./SkeletonHero";
import SkeletonWrapper from "./SkeletonWrapper";

interface SettingsSkeletonProps {
  menu?: React.ReactNode;
}

export default function SettingsSkeleton(
  props: SettingsSkeletonProps
): JSX.Element {
  const { menu } = props;

  return (
    <>
      <SkeletonWrapper noLoading>
        <SkeletonHero />
      </SkeletonWrapper>
      <div
        className={classNames(
          menu ? "lg:grid lg:grid-cols-12 lg:gap-x-5" : "w-full"
        )}
      >
        {menu}
        <div
          className={classNames(
            menu
              ? "space-y-6 sm:px-6 lg:col-span-9 lg:px-0"
              : "w-full flex justify-center"
          )}
        >
          <NarrowForm withMenu={!!menu}>
            <SkeletonWrapper>
              <SkeletonCard
                style={{
                  height: "calc(100vh - 148px)",
                }}
              ></SkeletonCard>
            </SkeletonWrapper>
          </NarrowForm>
        </div>
      </div>
    </>
  );
}
