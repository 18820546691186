import { useSearchParams } from "react-router-dom";

import { EndpointOptions, getEndpointOptions } from "../../../api";

export default function useEndpointOptions(defaults: EndpointOptions = {}) {
  const [searchParams] = useSearchParams();

  const options = getEndpointOptions(searchParams, defaults);

  return options;
}
