import React from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import { useQueryClient } from "@tanstack/react-query";

import { datasetQuery } from "../../../api/datasets";
import {
  activeDataAnalysisTasksQuery,
  activeDataImportTasksQuery,
  activeDatasetTasksQuery,
} from "../../../api/tasks";
import { DatasetDetail } from "../../../models/dataset";
import { Message } from "../../../models/message";
import {
  DatasetTaskZod,
  TaskStatus,
  finalTaskStates,
} from "../../../models/task";

export const useDatasetMessagesCallback = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const callback = React.useCallback(
    (msg: Message) => {
      const parser = DatasetTaskZod.safeParse(msg.eventArgs);
      if (!parser.success) {
        return;
      }

      const task = parser.data;

      const solutionId = task.solutionId.toString();
      const dataSetId = task.dataSetId.toString();

      const tasksToInvalidate = [
        activeDataAnalysisTasksQuery(solutionId),
        activeDataImportTasksQuery(solutionId),
        activeDatasetTasksQuery(solutionId, dataSetId),
      ];

      for (const query of tasksToInvalidate) {
        queryClient.invalidateQueries(query);
      }

      if ((finalTaskStates as readonly TaskStatus[]).includes(task.status)) {
        // import changes so many things that it's easier to invalidate everything
        queryClient.invalidateQueries();
      }

      if (task.status === "Failed") {
        return toast.error(
          task.taskName === "DatasetAnalysis"
            ? t("The dataset failed to be analyzed. Please try again.")
            : t("The dataset failed to be imported. Please try again.")
        );
      }
      if (task.status === "Canceled") {
        return toast.success(
          task.taskName === "DatasetAnalysis"
            ? t("The dataset analysis was canceled successfully.")
            : t("The dataset import was canceled successfully.")
        );
      }
      if (task.status === "Finished") {
        queryClient.setQueryData<DatasetDetail>(
          datasetQuery(dataSetId).queryKey,
          (prev) =>
            prev
              ? {
                  ...prev,
                  analyzed:
                    task.taskName === "DatasetAnalysis"
                      ? new Date()
                      : prev.analyzed,
                  imported:
                    task.taskName === "DatasetImport"
                      ? new Date()
                      : prev.imported,
                }
              : prev
        );
        return toast.success(
          task.taskName === "DatasetAnalysis"
            ? t("The dataset analysis was finished successfully.")
            : t("The dataset import was finished successfully.")
        );
      }
    },
    [queryClient, t]
  );

  return callback;
};
