import React from "react";

import timezones from "timezones-list";

import Select from "./Select";

interface TimezonePickerProps {
  id: string;
  label?: string;
  disabled?: boolean;
  info?: string;
  error?: string;
  value: string;
  onChange(value: string): void;
}

export default function TimezonePicker(
  props: TimezonePickerProps
): JSX.Element {
  const { id, label, disabled, info, error, value } = props;
  const { onChange } = props;

  const options = getTimezoneOptions();
  const selectedOption = options.find((o) => o.key === value) ?? null;

  return (
    <Select
      id={id}
      label={label}
      options={options}
      value={selectedOption}
      disabled={disabled}
      info={info}
      error={error}
      onChange={({ key }) => onChange(key)}
    />
  );
}

type TZOption = { offset: string; label: string };

const UNSUPPORTED_TIMEZONES = [
  "Asia/Pyongyang",
  "America/Caracas",
  "Australia/Lord_Howe",
];

const getTimezoneOptions = () => {
  const options = timezones
    .filter((tz) => !UNSUPPORTED_TIMEZONES.includes(tz.tzCode))
    .map((tz) => {
      let offset = "";
      let use = true;
      try {
        offset = getOffset(tz.tzCode) ?? tz.utc;
      } catch (e) {
        use = false;
      }

      return {
        label: `${tz.tzCode} (${offset})`,
        key: tz.tzCode,
        offset,
        use,
      };
    })
    .filter((o) => o.use)
    .concat([
      { label: "UTC (+00:00)", key: "UTC", offset: "+00:00", use: true },
    ]);
  options.sort(sortTimezones);

  return options;
};

const sortTimezones = (a: TZOption, b: TZOption) => {
  const tmp =
    parseFloat(a.offset.replace(":", ".")) -
    parseFloat(b.offset.replace(":", "."));

  if (tmp !== 0) {
    return tmp;
  }

  return a.label.localeCompare(b.label);
};

// inspiration: https://stackoverflow.com/a/64262840/20181968
const getOffset = (timeZone: string): string | undefined => {
  const timeZoneName = Intl.DateTimeFormat("ia", {
    timeZoneName: "short",
    timeZone,
  })
    .formatToParts()
    .find((i) => i.type === "timeZoneName")?.value;
  const offset = timeZoneName?.slice(3);

  if (!offset) {
    return undefined;
  }

  const matchData = offset.match(/([+-])(\d+)(?::(\d+))?/);
  if (!matchData) {
    return undefined;
  }

  const [, sign, hour, minute] = matchData;

  return `${sign}${String(hour ?? 0).padStart(2, "0")}:${String(
    minute ?? 0
  ).padStart(2, "0")}`;
};
