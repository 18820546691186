import React from "react";
import { Navigate, RouteObject } from "react-router";

import lazyWithRetry from "../../../services/lazyWithRetry";

const ProblemsPreview = lazyWithRetry(
  () => import("../pages/System/ProblemsPreview")
);

const SYTEM_PATH = "system";

export const systemRoute: RouteObject = {
  path: SYTEM_PATH,
  children: [
    { index: true, element: <Navigate to="/" /> },
    { path: "problemsPreview", element: <ProblemsPreview /> },
  ],
};
