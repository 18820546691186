import React from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router-dom";

import { useAuth } from "../../../../components/AuthProvider";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import LoginForm from "./LoginForm";

interface LoginProps {
  basePath?: string;
}

export default function Login(props: LoginProps): JSX.Element {
  const { basePath = "" } = props;
  const { t } = useTranslation();
  useDocumentTitle(t("Login"));

  const location = useLocation() as {
    state?: { from: string };
  };

  const { isAuthenticated } = useAuth();

  const from = location.state?.from ?? `${basePath}/auth`;

  if (isAuthenticated) {
    return <Navigate to={from} />;
  }

  return (
    <div className="flex justify-center">
      <LoginForm from={from} />
    </div>
  );
}
