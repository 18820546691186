import React from "react";
import { useParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { solutionQuery } from "../../api/solutions";
import useGeneralMenuItems from "../../hooks/useGeneralMenuItems";
import useSolutionMenuItems from "../../hooks/useSolutionMenuItems";
import useSolutionMessages from "../../hooks/useSolutionMessages";
import Sidebar from "../sidebar/Sidebar";
import BasicLayout from "./BasicLayout";

interface SolutionLayoutProps {
  children?: React.ReactNode;
}

export default function SolutionLayout({ children }: SolutionLayoutProps) {
  const { solutionId } = useParams();
  if (!solutionId) {
    throw new Error("Solution id not provided, wrong use of router.");
  }

  const { data: solution } = useQuery(solutionQuery(solutionId));

  useSolutionMessages();

  const [projectTop, projectBottom] = useGeneralMenuItems(solutionId);
  const [solutionTop] = useSolutionMenuItems(solutionId);

  const topNavigation = [projectTop, solutionTop];

  return (
    <Sidebar
      topNavigation={topNavigation}
      bottomNavigation={projectBottom}
      solution={solution}
    >
      <BasicLayout>{children}</BasicLayout>
    </Sidebar>
  );
}
