import React from "react";

import { useWebSocket } from "../../../components/WebSocketProvider";
import { Message, MessageZod } from "../models/message";

export default function useMessageHook(callback: (msg: Message) => void) {
  const webSocket = useWebSocket();

  React.useEffect(() => {
    const listener = function (message: MessageEvent) {
      const jdata = JSON.parse(message.data);
      const data = MessageZod.safeParse(jdata);
      if (data.success) {
        callback(data.data);
      } else {
        console.error(data.error);
      }
    };

    webSocket.addEventListener("message", listener);
    return () => webSocket.removeEventListener("message", listener);
  }, [webSocket, callback]);
}
