import { z } from "zod";

import { aggregateOptions } from "../../../models/aggregate";
import { DateTimeZod } from "../../../models/primitives";
import {
  GeneralImportModeZod,
  ImportExportFileTypeZod,
  ImportExportSeparatorZod,
} from "./file";
import { MeasurementZod } from "./measurement";
import { TaskStatusZod, TaskSubStatusZod } from "./task";

export const dataSources = ["File", "Sql", "Tis"] as const;
export const DataSourceZod = z.enum(dataSources);
export type DataSource = z.infer<typeof DataSourceZod>;

export const DatasetZod = z.object({
  dataSetId: z.number(),
  name: z.string(),
  source: DataSourceZod,
  size: z.number().nullable().optional(),
  rows: z.number().nullable().optional(),
  analyzed: DateTimeZod.nullable().optional(),
  isAnalyzed: z.boolean().default(false),
  imported: DateTimeZod.nullable().optional(),
  lastStatus: TaskStatusZod.nullable().optional().catch(undefined),
  lastSubStatus: TaskSubStatusZod.nullable().optional().catch(undefined),
  fileType: ImportExportFileTypeZod.nullable().optional(),
  columnSeparator: ImportExportSeparatorZod.nullable().optional(),
});
export type Dataset = z.infer<typeof DatasetZod>;

export const datasetImportModes = [
  GeneralImportModeZod.Enum.Replace,
  "SoftReplace",
  GeneralImportModeZod.Enum.Overwrite,
  GeneralImportModeZod.Enum.Append,
] as const;
export const DatasetImportModeZod = z.enum(datasetImportModes);
export type DatasetImportMode = z.infer<typeof DatasetImportModeZod>;

export const importExportZones = ["Utc", "Local"] as const;
export const ImportExportZoneZod = z.enum(importExportZones);
export type ImportExportZone = z.infer<typeof ImportExportZoneZod>;

export const ImportMeasurementZod = MeasurementZod.pick({
  name: true,
  aggregationMethod: true,
  unit: true,
});
export type ImportMeasurement = z.infer<typeof ImportMeasurementZod>;

// prettier-multiline-arrays-next-line-pattern: 5
export const samplingPeriods = ["Invalid", ...aggregateOptions] as const;
export const SamplingPeriodZod = z.enum(samplingPeriods);
export type SamplingPeriod = z.infer<typeof SamplingPeriodZod>;

export const PreviewRowZod = z
  .union([z.string(), z.number(), z.null()])
  .array();
export type PreviewRow = z.infer<typeof PreviewRowZod>;

export const DatasetDetailZod = DatasetZod.extend({
  connectionString: z.string().nullable().optional(),
  query: z.string().nullable().optional(),
  tisTable: z.string().nullable().optional(),
  fileName: z.string().nullable().optional(),
  firstRows: PreviewRowZod.array().catch([]),
  lastRows: PreviewRowZod.array().catch([]),
  mode: DatasetImportModeZod,
  samplingPeriod: SamplingPeriodZod,
  timestamp: z.string().nullable().optional(),
  timestampZone: ImportExportZoneZod,
  timestampFormat: z.string().catch("ISO"),
  measurements: ImportMeasurementZod.array(),
  partitioners: z.string().array(),
  columns: z.string().array().nullable().optional(),
  firstTimestamp: DateTimeZod.nullable().optional(),
  lastTimestamp: DateTimeZod.nullable().optional(),
  measurementIdsInFile: z.number().array().optional(),
  partitionIdsInFile: z.number().array().optional(),
  newMeasurementIds: z.number().array().optional(),
  newPartitionIds: z.number().array().optional(),
  nrLeafPartitionsInFile: z.number().nullable().optional(),
  nrMeasurementsInFile: z.number().nullable().optional(),
  nrNewAggregatePartitions: z.number().nullable().optional(),
  nrNewLeafPartitions: z.number().nullable().optional(),
  nrNewMeasurements: z.number().nullable().optional(),
  nrRemovedAggregatePartitions: z.number().nullable().optional(),
  nrRemovedLeafPartitions: z.number().nullable().optional(),
  nrRemovedMeasurements: z.number().nullable().optional(),
});

export type DatasetDetail = z.infer<typeof DatasetDetailZod>;
export type DatasetPatch = Partial<DatasetDetail>;
