import { DatasetDetail } from "../../../models/dataset";

export type MissingMapping = "timestamp" | "measurement" | "partitioner";

export default function useMappingsCheck(
  dataset: DatasetDetail | undefined
): MissingMapping[] {
  const missingMappings: MissingMapping[] = [];
  if (!dataset) {
    return [];
  }

  if (!dataset.timestamp) {
    missingMappings.push("timestamp");
  }

  if (dataset.measurements.length === 0) {
    missingMappings.push("measurement");
  }

  if (dataset.partitioners.length === 0) {
    missingMappings.push("partitioner");
  }

  return missingMappings;
}
