import React from "react";

import Card from "../../../../components/Card";

interface NarrowFormCardProps {
  title: React.ReactNode;
  description?: string | string[];
  children: React.ReactNode;
  className?: string;
  action?: React.ReactNode;
}

export default function NarrowFormCard(props: NarrowFormCardProps) {
  const { title, children, className = "", action } = props;
  let { description = [] } = props;

  if (!Array.isArray(description)) {
    description = [description];
  }

  return (
    <Card
      paddings={false}
      className={`space-y-6 sm:space-y-5 divide-y divide-gray-200 py-6 ${className}`}
    >
      <div className="px-6">
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {title}
          </h3>
          {action}
        </div>
        {description.map((content, index) => (
          <p key={index} className="mt-1 text-sm text-gray-500">
            {content}
          </p>
        ))}
      </div>
      {children}
    </Card>
  );
}
