import React from "react";
import { RouteObject } from "react-router-dom";

import BasicLayout from "./apps/PrognosAI/components/layouts/BasicLayout";
import ErrorPage from "./apps/PrognosAI/pages/ErrorPage/ErrorPage";
import RequireAuth from "./apps/PrognosAI/pages/Login/RequireAuth";
import NotFound from "./apps/PrognosAI/pages/NotFound/NotFound";
import { routes } from "./apps/PrognosAI/routes/index.old";

// TODO
// const routes: RouteObject[] = [
//   {
//     path: "/",
//     errorElement: (
//       <BasicLayout>
//         <ErrorPage />
//       </BasicLayout>
//     ),
//     children: prognosRoutes,
//   },
//   {
//     path: "*",
//     element: (
//       <RequireAuth>
//         <BasicLayout>
//           <NotFound />
//         </BasicLayout>
//       </RequireAuth>
//     ),
//   },
// ];

export default routes;
