import React from "react";

import classNames from "classnames";

import { FILTER_EL_STYLE } from ".";
import FormattedDatePicker from "../FormattedDatePicker";

interface FilterDatePickerProps {
  style?: React.StyleHTMLAttributes<HTMLInputElement>;
  value: string;
  onChange(value: string): void;
}

export default function FilterDatePicker(props: FilterDatePickerProps) {
  const { value, onChange } = props;

  return (
    <FormattedDatePicker
      selected={value ? new Date(value) : null}
      onChange={(date: Date | null) => date && onChange(date.toISOString())}
      // for some reason the customInput doesn't work with a separate component
      // even using proper ref forwarding
      customInput={
        <input
          type="text"
          className={classNames("grow ml-0.5 pl-2", FILTER_EL_STYLE)}
          style={{ lineHeight: "28px" }}
        />
      }
    />
  );
}
