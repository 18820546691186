import { toast } from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { z } from "zod";

import api, { getQueryString } from "../../../api";
import { EndpointOptions } from "../../../api";
import {
  Dataset,
  DatasetDetail,
  DatasetDetailZod,
  DatasetZod,
} from "../models/dataset";
import { DatasetDependency, DatasetImportErrorZod } from "../models/dependency";
import { PaginatedResponse, paginatedResponse } from "../models/response";
import {
  CompleteUpload,
  UploadLinkContainer,
  UploadLinkContainerZod,
} from "../models/upload";
import { getImportDetailPath, getImportsPath } from "../routes/imports";
import { activeDatasetTasksQuery } from "./tasks";

async function getDatasets(
  solutionId: string,
  options: EndpointOptions = {}
): Promise<PaginatedResponse<Dataset[]>> {
  const query = getQueryString(options);
  const datasetsQ = paginatedResponse(DatasetZod.array()).parse(
    (await api.get(`/Solutions/${solutionId}/Datasets?${query}`)).data
  );
  return datasetsQ;
}

export const datasetsQuery = (
  solutionId: string,
  options?: EndpointOptions
) => ({
  queryKey: ["datasets", solutionId, ...(options ? [options] : [])],
  queryFn: () => getDatasets(solutionId, options),
});

async function getDataset(dataSetId: string | number): Promise<DatasetDetail> {
  return DatasetDetailZod.parse((await api.get(`/Datasets/${dataSetId}`)).data);
}

export const datasetQuery = (dataSetId: string | number) => ({
  queryKey: ["dataset", dataSetId.toString()],
  queryFn: async () => await getDataset(dataSetId),
});

async function createDataset(
  solutionId: string,
  dataset: Omit<DatasetDetail, "dataSetId">
): Promise<DatasetDetail> {
  return DatasetDetailZod.parse(
    (await api.post(`/Solutions/${solutionId}/Datasets`, dataset)).data
  );
}

export const useCreateDataset = (
  solutionId: string,
  noRedirect = false,
  silent = false
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (dataset: Omit<DatasetDetail, "dataSetId">) =>
      createDataset(solutionId, dataset),
    onSuccess: ({ dataSetId }) => {
      if (!silent) {
        toast.success(t("Dataset saved successfully."));
      }
      queryClient.invalidateQueries(datasetsQuery(solutionId));
      if (!noRedirect) {
        navigate(getImportDetailPath(solutionId, dataSetId));
      }
    },
    onError: () => {
      if (!silent) {
        toast.error(t("An error occurred while saving. Please try again."));
      }
    },
  });
};

async function updateDataset(dataSetId: string, patch: Partial<DatasetDetail>) {
  return DatasetDetailZod.parse(
    (await api.patch(`/Datasets/${dataSetId}`, patch)).data
  );
}

export const useEditDataset = (solutionId: string, datasetId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (patch: Partial<DatasetDetail>) =>
      updateDataset(datasetId, patch),
    onSuccess: (newDataset) => {
      queryClient.setQueryData(["dataset", datasetId], newDataset);
      queryClient.invalidateQueries(datasetsQuery(solutionId));
    },
    onError: () => {
      toast.error(t("An error has occurred. Please try again."));
    },
  });
};

async function deleteDataset(dataSetId: number | string) {
  return api.delete(`/Datasets/${dataSetId}`);
}

export const useDeleteDataset = (
  solutionId: string,
  redirect = false,
  silent = false
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (dataSetId: number | string) => deleteDataset(dataSetId),
    onSuccess: (_, dataSetId) => {
      if (!silent) {
        toast.success(t("Dataset deleted successfully."));
      }
      queryClient.invalidateQueries(datasetsQuery(solutionId));
      queryClient.removeQueries(datasetQuery(dataSetId));
      if (redirect) {
        navigate(getImportsPath(solutionId));
      }
    },
    onError: () => {
      toast.error(t("An error occurred while deleting. Please try again."));
    },
  });
};

async function deleteDatasetsByIds(ids: number[]) {
  return api.delete(`/Datasets/Batch`, { data: { ids } });
}

export const useDeleteDatasetsByIds = (solutionId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: deleteDatasetsByIds,
    onSuccess: (_, ids) => {
      toast.success(t("Datasets deleted successfully."));
      queryClient.invalidateQueries(datasetsQuery(solutionId));
      queryClient.removeQueries(...ids.map((id) => datasetQuery(id)));
    },
    onError: () => {
      toast.error(t("An error occurred while deleting. Please try again."));
    },
  });
};

async function deleteDatasetsByFilter(
  solutionId: string | number,
  options: EndpointOptions
) {
  const query = getQueryString(options);
  return api.delete(`/Solutions/${solutionId}/Datasets?${query}`);
}

export const useDeleteDatasetsByFilter = (solutionId: string) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (options: EndpointOptions) =>
      deleteDatasetsByFilter(solutionId, options),
    onSuccess: () => {
      toast.success(t("Datasets deleted successfully."));
      queryClient.invalidateQueries(datasetsQuery(solutionId));
    },
    onError: () => {
      toast.error(t("An error occurred while deleting. Please try again."));
    },
  });
};

export async function getDatasetUploadContainer(
  dataSetId: number | string,
  fileName: string,
  fileSize: number
): Promise<UploadLinkContainer> {
  return UploadLinkContainerZod.parse(
    (
      await api.get(
        `/Datasets/${dataSetId}/UploadLink?fileName=${fileName}&fileSize=${fileSize}`
      )
    ).data
  );
}

export async function completeDatasetUpload(
  dataSetId: number | string,
  data: CompleteUpload
) {
  return api.post(`Datasets/${dataSetId}/CompleteUpload`, data);
}

export async function getDatasetDownloadLink(
  dataSetId: string | number
): Promise<string> {
  const response = z
    .object({ link: z.string() })
    .parse((await api.get(`/Datasets/${dataSetId}/DownloadLink`)).data);
  return response.link;
}

export const datasetDownloadLinkQuery = (dataSetId: string) => ({
  queryKey: ["datasetDownloadLink", dataSetId],
  queryFn: async () => await getDatasetDownloadLink(dataSetId),
});

async function startDatasetAnalysis(dataSetId: string | number) {
  return api.post(`/Datasets/${dataSetId}/Analyze`);
}

export const useStartDatasetAnalysis = (solutionId: string | number) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (dataSetId: string | number) => startDatasetAnalysis(dataSetId),
    onSuccess: (_, dataSetId) => {
      queryClient.invalidateQueries(datasetQuery(dataSetId));
      queryClient.invalidateQueries(
        activeDatasetTasksQuery(`${solutionId}`, `${dataSetId}`)
      );
    },
    onError: () => {
      toast.error(t("An error has occurred. Please try again."));
    },
  });
};

async function startDatasetImport(dataSetId: number | string, force = false) {
  return api.post(
    `/Datasets/${dataSetId}/Import${force ? "?force_on_replace=True" : ""}`
  );
}

export const useStartDatasetImport = (
  solutionId: string | number,
  dataSetId: string | number,
  onConfirmation: (deps: DatasetDependency[]) => void
) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: ({ force = false }: { force?: boolean } = {}) =>
      startDatasetImport(dataSetId, force),
    onSuccess: () => {
      queryClient.invalidateQueries(datasetQuery(dataSetId));
      queryClient.invalidateQueries(
        activeDatasetTasksQuery(`${solutionId}`, `${dataSetId}`)
      );
    },
    onError: (error) => {
      if (error instanceof AxiosError && error.response?.status === 422) {
        try {
          console.log(error.response.data);
          const response = DatasetImportErrorZod.parse(error.response.data);
          return onConfirmation(response.values);
        } catch (error) {
          console.error(error);
        }
      }
      toast.error(t("An error has occurred. Please try again."));
    },
  });
};
