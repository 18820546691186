import React from "react";
import { RouteObject } from "react-router";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getSolutionPath } from "../../PrognosAI/routes/solutions";

const Demand = lazyWithRetry(() => import("../../Demand/pages/Demand/Demand"));

const DEMAND_PATH = "demand";

export const demandRoute: RouteObject = {
  path: DEMAND_PATH,
  element: <Demand />,
};

export function getDemandPath(solutionId: string | number): string {
  return `/demand${getSolutionPath(solutionId)}/${DEMAND_PATH}`;
}
