import React from "react";
import { RouteObject } from "react-router";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getSolutionPath, getSolutionsPath } from "./solutions";

const Help = lazyWithRetry(() => import("../pages/Help/Help"));

const HELP_PATH = "help";

export const helpRoute: RouteObject = { path: HELP_PATH, element: <Help /> };

export function getHelpPath(solutionId: string | number | undefined): string {
  if (!solutionId) {
    return `${getSolutionsPath()}/${HELP_PATH}`;
  }

  return `${getSolutionPath(solutionId)}/${HELP_PATH}`;
}
