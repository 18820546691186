import React from "react";

import Card from "../../../components/Card";
import DisabledOverlay from "../pages/Import/components/DisabledOverlay";
import Breadcrumb from "./Breadcrumb";
import Breadcrumbs from "./Breadcrumbs";

interface PathLevel {
  label: React.ReactNode;
  address: string;
}

export type HeroPath = (PathLevel | null)[];

interface HeroProps {
  children?: React.ReactNode;
  path?: HeroPath;
  hideRootPath?: boolean;
  actions?: React.ReactNode;
  cardClassName?: string;
  disabled?: boolean;
}

export default function Hero(props: HeroProps): JSX.Element {
  const { children, path = [], actions } = props;
  const { cardClassName = "", disabled = false, hideRootPath = false } = props;
  return (
    <>
      <Breadcrumbs hideRoot={hideRootPath}>
        {path.map((level, index) =>
          level ? (
            <Breadcrumb key={level.address} to={level.address}>
              {level.label}
            </Breadcrumb>
          ) : (
            <div
              key={index}
              className="w-20 h-4 rounded-full animate-pulse bg-gray-200"
            ></div>
          )
        )}
      </Breadcrumbs>
      <div className={disabled ? "relative" : ""}>
        <Card
          className={`mb-4 flex justify-between items-center md:h-[4.5rem] space-x-1 ${cardClassName}`}
        >
          <h2 className="flex-grow">{children}</h2>
          {actions && (
            <div className="space-x-1 flex items-center">{actions}</div>
          )}
        </Card>
        {disabled && <DisabledOverlay />}
      </div>
    </>
  );
}
