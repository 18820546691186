import React from "react";
import { Navigate, RouteObject } from "react-router";

import { z } from "zod";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getSolutionPath } from "./solutions";

const BusinessHours = lazyWithRetry(
  () => import("../pages/BusinessHours/BusinessHours")
);
const BusinessHoursException = lazyWithRetry(
  () => import("../pages/BusinessHoursException/BusinessHoursException")
);
const EntityImportDetail = lazyWithRetry(
  () => import("../pages/EntityImportDetail/EntityImportDetail")
);

const BIZ_HOURS_PATH = "businessHours";

export const BusinessHoursTabZod = z.enum(["solution", "partitions", "import"]);
export type BusinessHoursTab = z.infer<typeof BusinessHoursTabZod>;
const TabEnum = BusinessHoursTabZod.Enum;

const EXCEPTION_PATH = "exceptions";

export const businessHoursRoute: RouteObject = {
  path: BIZ_HOURS_PATH,
  children: [
    { index: true, element: <Navigate to="./solution" replace /> },

    {
      path: TabEnum.solution,
      element: <BusinessHours tab={TabEnum.solution} />,
    },
    {
      path: TabEnum.partitions,
      element: <BusinessHours tab={TabEnum.partitions} />,
    },
    { path: TabEnum.import, element: <BusinessHours tab={TabEnum.import} /> },
    {
      path: `${TabEnum.import}/:importExportConfigId`,
      element: <EntityImportDetail entity="BusinessHours" />,
    },
    {
      path: `${EXCEPTION_PATH}/:businessRuleSetId`,
      element: <BusinessHoursException />,
    },
  ],
};

export function getBizHoursPath(solutionId: string | number): string {
  return `${getSolutionPath(solutionId)}/${BIZ_HOURS_PATH}`;
}

export function getPartitionBizHoursPath(
  solutionId: string | number,
  partitionId: string | number
): string {
  return `${getBizHoursPath(solutionId)}/partitions?partitionId=${partitionId}`;
}

export function getBizHoursImportPath(solutionId: string | number): string {
  return `${getBizHoursPath(solutionId)}/${TabEnum.import}`;
}

export function getBizHoursExceptionPath(
  solutionId: string | number,
  businessRuleSetId: string | number
): string {
  return `${getBizHoursPath(
    solutionId
  )}/${EXCEPTION_PATH}/${businessRuleSetId}`;
}
