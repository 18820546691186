import { z } from "zod";

export const aggregationMethods = ["Sum", "Mean", "Median"] as const;
export const AggregationMethodZod = z.enum(aggregationMethods);
export type AggregationMethod = z.infer<typeof AggregationMethodZod>;

export const MeasurementZod = z.object({
  measurementId: z.number(),
  name: z.string(),
  aggregationMethod: AggregationMethodZod,
  unit: z.string().nullable().optional(),
});

export type Measurement = z.infer<typeof MeasurementZod>;
