import React from "react";
import { useTranslation } from "react-i18next";

import Button from "../../../components/buttons/Button";
import Pagination from "./Pagination";
import DataTableBatchControl from "./datatable/DataTableBatchControl";
import DataTableFilterControl from "./datatable/DataTableFilterControl";
import DataTableHead from "./datatable/DataTableHead";
import DataTableRow from "./datatable/DataTableRow";
import DataTableSkeleton from "./datatable/DataTableSkeleton";
import {
  Column,
  DataTableGenericHeadProps,
  DataTableGenericRowProps,
  Row,
} from "./datatable/types";
import Table from "./table/Table";
import TableBody from "./table/TableBody";
import TableCell from "./table/TableCell";
import TableRow from "./table/TableRow";

interface DataTableProps<C extends Column[], R extends Row<C>>
  extends DataTableGenericRowProps<C, R>,
    DataTableGenericHeadProps<C> {
  id?: string;
  className?: string;
  rows: R[];
  showSkeleton?: boolean;
  disableOverflowX?: boolean;
  page?: number;
  pageSize?: number;
  totalCount?: number;
  bordered?: boolean;
  cellPadding?: string;
  emptyPlaceholder?: string;
  onBatchDelete?(rows: R[]): void;
  onAllDelete?(): void;
}

export default function DataTable<C extends Column[], R extends Row<C>>(
  props: DataTableProps<C, R>
): JSX.Element {
  const { id, className, columns, rows } = props;
  const { rowKey, actions, filter = [] } = props;
  const { showSkeleton = false, disableOverflowX = false } = props;
  const { page = 1, pageSize, totalCount, bordered } = props;
  const { onFilter, onBatchDelete, onAllDelete } = props;

  const { t } = useTranslation();
  const { emptyPlaceholder = t("The table is empty - no data found.") } = props;

  const [showFilters, setShowFilters] = React.useState(false);

  const [selectedRows, setSelectedRows] = React.useState<R[]>([]);
  const [selectedAll, setSelectedAll] = React.useState(false);

  const shownColumns = columns.filter((c) => !c.hidden);

  const isFilterable = shownColumns.some((c) => c.filterable);

  const displayFilters = showFilters || filter.length > 0;

  const displayPagination = !!pageSize && !!totalCount && pageSize < totalCount;

  const hasCheckboxColumn = !!onBatchDelete;
  const hasActionsColumn = !!actions?.length;

  const areAllPageRowsSelected = !rows.some(
    (row) =>
      !selectedRows.some(
        (r) => getRowKeyValue(rowKey, r) === getRowKeyValue(rowKey, row)
      )
  );

  const handleBatchDelete = () => {
    if (onAllDelete && selectedAll) {
      onAllDelete();
    } else if (onBatchDelete) {
      onBatchDelete(selectedRows);
    }
    setSelectedRows([]);
    setSelectedAll(false);
  };

  return (
    <>
      <div className="flex items-center space-x-4">
        {isFilterable && onFilter && (
          <DataTableFilterControl
            id={id ? `${id}-tableFilterControl` : "tableFilterControl"}
            state={
              !displayFilters ? "hidden" : filter.length === 0 ? "shown" : "set"
            }
            onReset={() => onFilter([])}
            onShow={() => setShowFilters(true)}
            onHide={() => setShowFilters(false)}
          />
        )}
        {hasCheckboxColumn && (
          <DataTableBatchControl
            id={id ? `${id}-tableBatchControl` : "tableBatchControl"}
            selectedCount={selectedRows.length}
            selectedAll={selectedAll}
            onDelete={handleBatchDelete}
            onSelectAll={
              !!onAllDelete && !selectedAll && areAllPageRowsSelected
                ? () => setSelectedAll(true)
                : undefined
            }
          />
        )}
      </div>
      <Table
        id={id}
        className={className}
        disableOverflowX={disableOverflowX}
        style={{ minHeight: onFilter && !disableOverflowX ? 300 : undefined }}
        bordered={bordered}
      >
        {shownColumns.some((col) => col.label) && (
          <DataTableHead
            {...props}
            tableId={id}
            columns={shownColumns}
            hasActionsColumn={hasActionsColumn}
            showFilters={displayFilters}
            checkbox={
              hasCheckboxColumn
                ? {
                    checked: selectedAll || selectedRows.length > 0,
                    variant:
                      selectedAll || areAllPageRowsSelected
                        ? "primary"
                        : "secondary",
                    onChange: (e) => {
                      if (e.target.checked) {
                        setSelectedRows((prev) =>
                          prev
                            .filter(
                              (row) =>
                                !rows.some(
                                  (r) =>
                                    getRowKeyValue(rowKey, r) ===
                                    getRowKeyValue(rowKey, row)
                                )
                            )
                            .concat(rows)
                        );
                      } else {
                        setSelectedRows((prev) =>
                          prev.filter(
                            (row) =>
                              !rows.some(
                                (r) =>
                                  getRowKeyValue(rowKey, r) ===
                                  getRowKeyValue(rowKey, row)
                              )
                          )
                        );
                        setSelectedAll(false);
                      }
                    },
                  }
                : undefined
            }
          />
        )}
        <TableBody>
          {showSkeleton && (
            <DataTableSkeleton
              size={pageSize ?? 5}
              colSpan={shownColumns.length + (hasActionsColumn ? 1 : 0)}
            />
          )}
          {!showSkeleton && rows.length === 0 && (
            <TableRow>
              <TableCell
                className="text-center space-y-2"
                colSpan={shownColumns.length + (hasActionsColumn ? 1 : 0)}
              >
                <p>{emptyPlaceholder}</p>
                {filter.length > 0 && onFilter && (
                  <p>
                    <Button
                      id={id ? `${id}-resetFilters` : undefined}
                      onClick={() => onFilter([])}
                    >
                      {t("Reset filters")}
                    </Button>
                  </p>
                )}
              </TableCell>
            </TableRow>
          )}
          {!showSkeleton &&
            rows.map((row, index) => (
              <DataTableRow
                {...props}
                columns={shownColumns}
                key={getRowKeyValue(rowKey, row)}
                id={getRowId(id, rowKey, row, index)}
                row={row}
                checkbox={
                  hasCheckboxColumn
                    ? {
                        checked:
                          selectedAll ||
                          selectedRows.some(
                            (r) =>
                              getRowKeyValue(rowKey, r) ===
                              getRowKeyValue(rowKey, row)
                          ),
                        variant: selectedAll ? "secondary" : "primary",
                        onChange: (e) => {
                          if (selectedAll) {
                            if (!e.target.checked) {
                              setSelectedAll(false);
                              setSelectedRows(
                                rows.filter(
                                  (r) =>
                                    getRowKeyValue(rowKey, r) !==
                                    getRowKeyValue(rowKey, row)
                                )
                              );
                            }
                            return;
                          }
                          if (e.target.checked) {
                            setSelectedRows((prev) => [...prev, row]);
                          } else {
                            setSelectedRows((prev) =>
                              prev.filter(
                                (r) =>
                                  getRowKeyValue(rowKey, r) !==
                                  getRowKeyValue(rowKey, row)
                              )
                            );
                          }
                        },
                      }
                    : undefined
                }
              />
            ))}
        </TableBody>
      </Table>
      {displayPagination && (
        <Pagination
          id={id ? `${id}-pagination` : "pagination"}
          page={page}
          pageSize={pageSize}
          totalCount={totalCount}
        />
      )}
    </>
  );
}

function getRowKeyValue(
  rowKey: string,
  row: { [key: string]: unknown }
): string {
  return row[rowKey]?.toString() ?? JSON.stringify(row);
}

function getRowId(
  id: string | undefined,
  rowKey: string,
  row: { [key: string]: unknown },
  index: number
): string | undefined {
  if (!id) {
    return undefined;
  }

  if (!(rowKey in row)) {
    return `${id}-row-idx${index}`;
  }

  return `${id}-row-${row[rowKey]}`;
}
