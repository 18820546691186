import React from "react";
import { useTranslation } from "react-i18next";

import { useQuery } from "@tanstack/react-query";

import { csrfTokenQuery } from "../../../../api/user";
import env from "../../../../services/env";

export default function LogoutForm(): JSX.Element {
  const { data: csrf } = useQuery(csrfTokenQuery());

  const { t } = useTranslation();

  return (
    <form
      className="text-xs font-medium text-gray-500 group-hover:text-gray-700"
      action={`${env.API_URL}/accounts/logout/`}
      method="POST"
    >
      <input
        type="hidden"
        name="csrfmiddlewaretoken"
        value={csrf?.csrftoken ?? ""}
      />
      <button
        id="sidebarSignOutButton"
        type="submit"
        disabled={!csrf?.csrftoken}
      >
        {t("Sign out")}
      </button>
    </form>
  );
}
