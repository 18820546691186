import React from "react";
import { Navigate, RouteObject } from "react-router";

import { z } from "zod";

import lazyWithRetry from "../../../services/lazyWithRetry";
import { getSolutionPath, getSolutionsPath } from "./solutions";

const Settings = lazyWithRetry(() => import("../pages/Settings/Settings"));
const Localization = lazyWithRetry(
  () => import("../pages/Settings/sections/Localization")
);
const Export = lazyWithRetry(() => import("../pages/Settings/sections/Export"));
const Visualizations = lazyWithRetry(
  () => import("../pages/Settings/sections/Visualizations")
);
const ApiKeys = lazyWithRetry(
  () => import("../pages/Settings/sections/ApiKeys")
);

const USER_SETTINGS_PATH = "userSettings";

export const settingsPages = [
  "localization",
  "export",
  "visualizations",
  "apiKeys",
] as const;
export const SettingsPageZod = z.enum(settingsPages);
export type SettingsPage = z.infer<typeof SettingsPageZod>;
const PageEnum = SettingsPageZod.Enum;

export const userSettingsRoute: RouteObject = {
  path: USER_SETTINGS_PATH,
  element: <Settings />,
  children: [
    { index: true, element: <Navigate to={PageEnum.localization} replace /> },
    { path: PageEnum.localization, element: <Localization /> },
    { path: PageEnum.export, element: <Export /> },
    { path: PageEnum.visualizations, element: <Visualizations /> },
    { path: PageEnum.apiKeys, element: <ApiKeys /> },
  ],
};

export function getUserSettingsPath(
  solutionId: string | number | undefined
): string {
  if (!solutionId) {
    return `${getSolutionsPath()}/${USER_SETTINGS_PATH}`;
  }

  return `${getSolutionPath(solutionId)}/${USER_SETTINGS_PATH}`;
}
