import { Aggregate } from "../../../models/aggregate";

export function getAggregateLength(scale: Aggregate): number {
  const minute = 1000 * 60;
  const hour = minute * 60;
  const day = hour * 24;
  const year = day * 365;
  switch (scale) {
    case "10m":
      return minute * 10;
    case "15m":
      return minute * 15;
    case "30m":
      return minute * 30;
    case "1h":
      return hour;
    case "1D":
      return day;
    case "1W":
      return day * 7;
    case "1M":
      return year / 12;
    case "1Q":
      return year / 4;
    case "1Y":
      return year;
  }
}

type DateKeys<T> = {
  [K in keyof T]: T[K] extends Date ? K : never;
}[keyof T];
type ValueKeys<T> = {
  [K in keyof T]: T[K] extends number | null ? K : never;
}[keyof T];

export function addNullPoints<T extends Record<string, Date | number | null>>(
  seriesData: T[],
  dateKey: DateKeys<T>,
  numberKey: ValueKeys<T>,
  scale: Aggregate
): T[] {
  seriesData.sort(
    (a, b) => (a[dateKey] as Date).valueOf() - (b[dateKey] as Date).valueOf()
  );

  const treshold = getAggregateLength(scale) / 2;

  const dataWithNulls: T[] = [];
  for (const point of seriesData) {
    let prev = dataWithNulls.at(-1);
    while (
      prev &&
      (prev[dateKey] as Date).valueOf() < (point[dateKey] as Date).valueOf()
    ) {
      const tmpX = getNextDate(prev[dateKey] as Date, scale);

      if ((point[dateKey] as Date).valueOf() - tmpX.valueOf() >= treshold) {
        dataWithNulls.push({ [dateKey]: tmpX, [numberKey]: null } as T);
        prev = dataWithNulls.at(-1);
      } else {
        break;
      }
    }

    dataWithNulls.push(point);
  }
  return dataWithNulls;
}

function getNextDate(date: Date, scale: Aggregate): Date {
  const next = new Date(date);
  switch (scale) {
    case "10m":
      next.setUTCMinutes(next.getUTCMinutes() + 10);
      return next;
    case "15m":
      next.setUTCMinutes(next.getUTCMinutes() + 15);
      return next;
    case "30m":
      next.setUTCMinutes(next.getUTCMinutes() + 30);
      return next;
    case "1h":
      next.setUTCHours(next.getUTCHours() + 1);
      return next;
    case "1D":
      next.setUTCDate(next.getUTCDate() + 1);
      return next;
    case "1W":
      next.setUTCDate(next.getUTCDate() + 7);
      return next;
    case "1M":
      next.setUTCMonth(next.getUTCMonth() + 1);
      return next;
    case "1Q":
      next.setUTCMonth(next.getUTCMonth() + 3);
      return next;
    case "1Y":
      next.setFullYear(next.getFullYear() + 1);
      return next;
  }
}
