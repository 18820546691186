import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";

import { HomeIcon, ShareIcon } from "@heroicons/react/24/outline";

import { getDemandPath } from "../../../Demand/routes/demand";
import { solutionQuery } from "../../api/solutions";
import { getSolutionPath } from "../../routes/solutions";
import Sidebar, { SidebarItem } from "../sidebar/Sidebar";
import BasicLayout from "./BasicLayout";
import { useGeneralMenuItems } from "./DemandGeneralLayout";

interface DemandSolutionLayoutProps {
  children?: React.ReactNode;
}

export default function DemandSolutionLayout({
  children,
}: DemandSolutionLayoutProps) {
  const { solutionId } = useParams();
  if (!solutionId) {
    throw new Error("Solution id not provided, wrong use of router.");
  }

  const { data: solution } = useQuery(solutionQuery(solutionId));

  const [projectTop, projectBottom] = useGeneralMenuItems(solutionId);
  const [solutionTop] = useSolutionMenuItems(solutionId);

  const topNavigation = [projectTop, solutionTop];

  return (
    <Sidebar
      topNavigation={topNavigation}
      bottomNavigation={projectBottom}
      solution={solution}
    >
      <BasicLayout>{children}</BasicLayout>
    </Sidebar>
  );
}

function useSolutionMenuItems(
  solutionId: string
): [SidebarItem[], SidebarItem[]] {
  const location = useLocation();
  const pathPrefix = "/demand" + getSolutionPath(solutionId);
  const { t } = useTranslation();

  const topItems = [
    {
      key: "dashboard",
      name: t("Overview"),
      href: pathPrefix,
      icon: HomeIcon,
      current: !!location.pathname.match(/^\/demand\/auth\/\d+\/?$/),
    },
    {
      key: "canvas",
      name: t("Business map"),
      href: getDemandPath(solutionId),
      icon: ShareIcon,
      current: location.pathname.startsWith(getDemandPath(solutionId)),
    },
    // {
    //   key: "results",
    //   name: t("Results"),
    //   href: getResultsPath(solutionId),
    //   icon: PresentationChartLineIcon,
    //   current: location.pathname.startsWith(getResultsPath(solutionId)),
    // },
    // {
    //   key: "solutionSettings",
    //   name: t("Project Settings"),
    //   href: getSolutionSettingsPath(solutionId),
    //   icon: Cog6ToothIcon,
    //   current: location.pathname.startsWith(
    //     getSolutionSettingsPath(solutionId)
    //   ),
    // },
  ];

  return [topItems, []];
}
